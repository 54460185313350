import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import requestClient from '../../../Apis/requestClient';
import { useGlobalContext } from '../../../Utils/Context';
import AttachFile from '../../AttachFile/AttachFile';
import { Button } from '../../Buttons/ButtonComponent';
import Pagination from '../../Buttons/Pagination';
import Filter from '../../Common/Filter';
import { formatDateString } from '../../Common/Format';
import Icon from '../../Common/Icon';
import useCloseModalOnOutsideClick from '../../OnOutsideClick/OnOutsideClick';
import BreadcrumbProject from '../BreadcrumbProject';
import UpdateMemberPopup from './UpdateMemberPopup';

const Member = () => {
  const { roleUser, userInfo, changeIdUserInfomation } = useGlobalContext();
  const { projectId } = useParams();
  const [deleteIdMember, setDeleteIdMember] = useState();
  const [projectRole, setProjectRole] = useState(false);
  const [projectLocation, setProjectLocation] = useState(false);
  const [projectRoleId, setProjectRoleID] = useState();
  const [operation, setOperation] = useState(false);
  const [isUpdateMember, setIsUpdateMember] = useState(false);
  const navigate = useNavigate();
  const project = JSON.parse(localStorage.getItem('project'));
  const createdBy = project?.createdBy;
  // Pagination
  const itemNumber = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [memberList, setMemberList] = useState([]);
  const memberUpdate = memberList?.filter((item) => item?.roleNumber !== 0 || item?.addBy === 1);

  const [pagination, setPagination] = useState({ totalItems: 0 });
  const { t } = useTranslation();

  const modalRef = useRef(null);
  useCloseModalOnOutsideClick(modalRef, setOperation);
  useCloseModalOnOutsideClick(modalRef, setProjectRole);
  useCloseModalOnOutsideClick(modalRef, setProjectLocation);

  const [userName, setUserName] = useState('');
  const [filterName, setFilterName] = useState('');
  const [arrange, setArrange] = useState('desc');
  const getMemberByProjectId = async () => {
    try {
      const res = await requestClient({
        endPoint: `/member/getMembersProject?page=${currentPage}&size=${itemNumber}&projectId=${projectId}${
          userName ? `&userName=${userName}` : ''
        }${filterName?.order ? `&orders=${filterName?.order}+${arrange}` : ''}`,
        method: 'GET'
      });

      setMemberList(res?.data?.members);
      setPagination({ totalItems: res?.data?.totalItems });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateRole = (userId, newRole, newLocation) => {
    const userIndex = memberList.findIndex((user) => user.id === userId);
    if (userIndex !== -1) {
      const updatedUsers = [...memberList];
      updatedUsers[userIndex] = { ...updatedUsers[userIndex], role: newRole, location: newLocation };
      setMemberList(updatedUsers);
    } else {
      console.log(t('project.user-dose-not'));
    }
  };

  const [memberInfo, setMemberInfo] = useState({});
  const handleUpdate = (data) => {
    setMemberInfo(data);
    setIsUpdateMember(true);
  };

  const handleDelete = async (memberId) => {
    try {
      await requestClient({ endPoint: `/member/deleteMember/${memberId}`, method: 'DELETE' });
      getMemberByProjectId();
      toast.success(t('project.successfully-removed-member'), {
        position: 'top-right'
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [isModalVisible, setModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const modalReff = useRef(null);

  const handleDeleteClick = (memberId) => {
    setItemToDelete(memberId);
    setModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    // Xử lý xác nhận xóa
    handleDelete(itemToDelete); // Gọi hàm xóa với ID đã lưu
    setModalVisible(false); // Đóng modal sau khi xóa
  };

  const handleDeleteCancel = () => {
    setModalVisible(false); // Đóng modal khi hủy
  };
  useEffect(() => {
    getMemberByProjectId();
  }, [projectId, userName, filterName, arrange, currentPage]);

  return (
    <div className="h-[100vh] w-full overflow-y-auto  px-4">
      <BreadcrumbProject data={[{ name: t('project.member'), link: '' }]} />

      {/* Top */}
      <div className="flex justify-between py-4">
        <div>
          <h2 className="text-xl font-semibold text-neu-400">{t('job.members-list')}</h2>
          <p className="text-sm text-neu-200">{t('project.list-project-participants')}</p>
        </div>
        {(project?.roleProject !== 3 || roleUser === 'ROLE_ADMIN' || roleUser === 'ROLE_SUPER_ADMIN') && (
          <div>
            <Button
              icon={<Icon name="plus_circle" className="" />}
              content={t('department.more-members')}
              onClick={() => navigate('add-member')}
              className="rounded-md bg-primary-100 px-3 py-2 text-white hover:bg-blue-800"
            />
          </div>
        )}
      </div>

      {/* <AddMemberPopup isOpen={isAddMemberPopup} setIsOpen={setIsAddMemberPopup} /> */}

      <div className="mt-6 flex justify-between gap-6">
        {/* Research project member */}
        <div className="relative flex w-[70%]">
          <div className="absolute left-3 translate-y-1/2">
            <Icon name="zoom_search" className="stroke-neu-200" />
          </div>

          <input
            type="text"
            onChange={(e) => setUserName(e.target.value)}
            placeholder={t('project.usernamr-or-account')}
            className="w-full rounded-lg bg-white py-2 pr-3 pl-10 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] placeholder:text-sm focus:border-primary-100 focus:outline-none"
          />
        </div>

        {/* Filter */}
        <div className="w-[30%]">
          <Filter
            filterList={[
              { name: t('project.role'), order: `role_project` },
              { name: t('project.position'), order: `position_name` },
              { name: t('project.due-date'), order: `expiration_date` },
              { name: t('project.date-added-project'), order: `add_date` },
              { name: t('project.most-recent-activity'), order: `latest_operating_date` }
            ]}
            setCurrentPage={setCurrentPage}
            filterName={filterName}
            setFilterName={setFilterName}
            arrange={arrange}
            setArrange={setArrange}
          />
        </div>
      </div>

      {/* Danh sách thành viên */}
      <div className="mt-4 h-[68vh] overflow-y-auto">
        <div className="rounded-xl border bg-[#F2F2F2]">
          <table className="w-full table-auto ">
            <thead className="w-full  ">
              <tr className="  bg-[#F2F2F2] ">
                <th className="w-[25%] rounded-tl-xl  py-4 pl-4 text-left">{t('project.member')}</th>
                <th className="w-[15%]  p-4 text-left">{t('project.role')}</th>
                <th className="w-[15%]  p-4 text-left">{t('project.position')} </th>
                <th className="w-[20%]   py-3 px-4 text-left">{t('project.due-date')} </th>
                <th className="w-[20%] rounded-tr-xl py-3 px-4 text-left">{t('project.activity')}</th>
                {(roleUser === 'ROLE_ADMIN' || roleUser === 'ROLE_SUPER_ADMIN' || createdBy === userInfo?.userID) && (
                  <th className="w-[5%] rounded-tr-xl"></th>
                )}
              </tr>
            </thead>

            <tbody className="bg-white">
              {memberUpdate?.map((item, index) => {
                return (
                  <tr
                    className={`h-16 border-t  text-sm font-normal ${item.disable === 'Y' && 'opacity-30'}`}
                    key={index}
                  >
                    {/* Name - Image */}
                    <td className="flex items-center gap-4 rounded-bl-xl py-3 px-4 text-left">
                      <div onClick={() => changeIdUserInfomation(item?.userId)} className="cursor-pointer">
                        <AttachFile
                          attachType="UserAvatar"
                          entity="users"
                          seq={item?.userId}
                          viewMode={true}
                          mode="list"
                          className="h-12 w-12 rounded-full object-cover"
                        />
                      </div>
                      <div>
                        <h2 className="font-semibold">{item?.fullName}</h2>
                        <p className="text-gray-400">{item?.userName ? `@${item?.userName}` : ''}</p>
                      </div>
                    </td>
                    {/* Role */}
                    <td className="relative py-3 pl-4 text-left">
                      <div
                        className="flex w-[166px] justify-between rounded border p-2"
                        onClick={() => {
                          setProjectRole(true), setProjectRoleID(item?.userId);
                        }}
                      >
                        {!item?.isAdmin ? (
                          <span className="w-full">
                            {item?.roleNumber === 1 && 'Owner'}
                            {item?.roleNumber === 2 && 'Editor'}
                            {item?.roleNumber === 3 && 'Viewer'}
                          </span>
                        ) : (
                          <span className="w-full">
                            {item?.roleNumber === 1 && 'Owner'}
                            {item?.roleNumber !== 1 && 'Admin'}
                          </span>
                        )}

                        <Icon name="chevron_down" className="hidden stroke-gray-600" />
                      </div>

                      {projectRole === true && item?.userId === projectRoleId && (
                        <div
                          className="absolute z-30 mt-2 hidden w-[166px] rounded bg-white p-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.24)]"
                          ref={modalRef}
                        >
                          <p
                            className="p-2 hover:bg-[#D9D9D9]"
                            onClick={() => {
                              handleUpdateRole(item.id, 1, item?.location), setProjectRole(false);
                            }}
                          >
                            Owner
                          </p>
                          <p
                            className="p-2 hover:bg-[#D9D9D9]"
                            onClick={() => {
                              handleUpdateRole(item.id, 2, item?.location), setProjectRole(false);
                            }}
                          >
                            Editor
                          </p>
                          <p
                            className="p-2 hover:bg-[#D9D9D9]"
                            onClick={() => {
                              handleUpdateRole(item.id, 3, item?.location), setProjectRole(false);
                            }}
                          >
                            Viewer
                          </p>
                        </div>
                      )}
                    </td>
                    {/* Position */}
                    <td className="relative py-3 pl-4 text-left">
                      {item?.positionDTOList?.length && !item?.isAdmin ? (
                        <>
                          <div
                            className={`flex w-[200px] justify-between gap-4 rounded border p-2 ${
                              item?.positionDTOList?.length > 1 ? 'cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              if (item?.positionDTOList?.length > 1) {
                                setProjectLocation(true), setProjectRoleID(item?.userId);
                              }
                            }}
                          >
                            <h2>{item?.positionDTOList?.at(0)?.positionName}</h2>
                            <Icon
                              name="chevron_down"
                              className={`stroke-gray-600 ${item?.positionDTOList?.length > 1 ? '' : 'hidden'}`}
                            />
                          </div>
                          {projectLocation === true && item?.userId === projectRoleId && (
                            <div
                              className="absolute z-30 mt-2 w-[200px] rounded bg-white p-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.24)]"
                              ref={modalRef}
                            >
                              {item?.positionDTOList?.map((item, index) => {
                                return (
                                  <p
                                    key={index}
                                    className="p-2 hover:bg-primary-50"
                                    onClick={() => {
                                      handleUpdateRole(item.id, item.role, 1), setProjectLocation(false);
                                    }}
                                  >
                                    {item?.positionName}
                                  </p>
                                );
                              })}
                            </div>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    {/* Expiration date */}
                    <td className="p-2 px-4 text-left text-neu-200">
                      <input
                        type="date"
                        defaultValue={
                          item?.expirationDate
                            ? new Date(item.expirationDate).toLocaleDateString('en-CA') // Định dạng YYYY-MM-DD
                            : ''
                        }
                        disabled
                        className="rounded border bg-white p-2 text-black outline-none"
                      />
                    </td>

                    <td className="p-2 px-4 text-left ">
                      <p className="text-[#686868]">
                        {t('project.date-added')}:{' '}
                        <span className="pl-2 font-semibold text-black">
                          {item?.addDate ? formatDateString({ dateString: item?.addDate }) : ''}
                        </span>
                      </p>
                      <p className="py-1 text-[#686868]">
                        {t('project.added-by')}:{' '}
                        <span className="pl-2  font-semibold text-black">{item?.addByName}</span>
                      </p>
                      <p className="text-[#686868]">
                        {t('project.most-recent-activity')}:{' '}
                        <span className="pl-2  font-semibold text-black">
                          {item?.latestOperatingDate ? formatDateString({ dateString: item?.latestOperatingDate }) : ''}
                        </span>
                      </p>
                    </td>
                    {/* Operation */}
                    <td className="relative flex justify-end p-2 px-4 text-left">
                      {(roleUser === 'ROLE_ADMIN' ||
                        roleUser === 'ROLE_SUPER_ADMIN' ||
                        item?.ownerId === userInfo?.userID) && (
                        <div
                          onClick={() => {
                            setOperation(!operation), setDeleteIdMember(item.userId);
                          }}
                          className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg bg-[#F2F2F2] p-4"
                        >
                          <Icon name="small_button" />
                        </div>
                      )}
                      {operation === true && deleteIdMember === item.userId && (
                        <div>
                          <ul
                            ref={modalReff}
                            className="absolute right-16 top-0 z-30 w-[180px] rounded-md bg-white text-center font-medium shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]"
                          >
                            <li
                              onClick={() => handleUpdate(item)}
                              className="flex cursor-pointer gap-x-2 border border-b p-2 text-neu-400 hover:bg-primary-50"
                            >
                              <Icon name={'edit_2'} className="fill-neu-400 " />
                              <p> {t('project.edit')}</p>
                            </li>
                            {!item?.isAdmin && item?.roleNumber !== 1 && (
                              <li
                                onClick={() => handleDeleteClick(item.memberId)} // Sử dụng hàm này để mở modal xác nhận
                                className="flex cursor-pointer gap-x-2 p-2 text-neu-800 hover:bg-primary-50"
                              >
                                <Icon name={'trash_alt'} className="fill-red-500 " />
                                <p>{t('department.remove-member')}</p>
                              </li>
                            )}
                          </ul>

                          {/* Popup xác nhận */}
                          {isModalVisible && (
                            <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50">
                              <div className="h-[262px] w-[464px] rounded-lg bg-white p-4 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]">
                                <div className="flex flex-col items-center gap-y-5 p-4">
                                  <Icon name="trash_can_1" />

                                  <div className="text-center">
                                    <p
                                      className="whitespace-normal break-words font-medium text-neu-400"
                                      style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
                                    >
                                      {`${t('project.delete-card')} "${item?.fullName}"`}
                                    </p>
                                    <p className="mt-1 text-sm font-normal text-neu-200">
                                      {t('project.all-information-member')}
                                    </p>
                                  </div>

                                  <div className="flex justify-center gap-x-10">
                                    <Button
                                      content={t('project.cancel')}
                                      onClick={handleDeleteCancel}
                                      className="min-w-[82px] border border-neu-800 py-1.5 px-3 text-sm text-neu-800"
                                    />

                                    <Button
                                      type="submit"
                                      content={t('project.confirm')}
                                      onClick={handleDeleteConfirm}
                                      className="min-w-[82px] cursor-pointer border border-neu-800 bg-neu-800 py-1.5 px-3 text-sm text-neu-300"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {pagination?.totalItems > 10 ? (
        <div className="mt-4">
          <Pagination {...{ setCurrentPage, currentPage, pagination, itemNumber }} />
        </div>
      ) : (
        ''
      )}
      <UpdateMemberPopup
        projectType={project?.type}
        isOpen={isUpdateMember}
        setIsOpen={setIsUpdateMember}
        projectId={projectId}
        memberInfo={memberInfo}
        getMemberByProjectId={getMemberByProjectId}
      />
    </div>
  );
};

export default Member;
