import { t } from 'i18next';
import ChangeAvatarIcon from '../../Assets/Images/icon-change-avatar.svg';
import icDispatch from '../../Assets/Images/Icons/ic-dispatch.svg';
import backendURL from '../../config';

const DownLoadFile = (props) => {
  // Hàm kiểm tra file text
  const textExtensions = ['txt', 'doc', 'docx', 'pdf'];
  const isTextFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return textExtensions.includes(fileExtension);
  };

  // Hàm kiểm tra file ảnh
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const isImageFile = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  // Đảm bảo xử lý đúng sự kiện xóa
  const handleRemove = (index) => {
    if (props.handleRemove) {
      props.handleRemove(index);
    }
  };

  const handleDownload = (index) => {
    if (props.handleDownLoad) {
      props.handleDownLoad(index);
    }
  };
  const getFileTypeLabel = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'PDF';
      case 'doc':
      case 'docx':
        return 'WORD';
      case 'xlsx':
      case 'xls':
        return 'EXCEL';
      case 'png':
      case 'jpg':
      case 'jpeg':
        return 'IMAGE';
      default:
        return 'FILE';
    }
  };
  const getFileTypeColor = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase(); // Lấy phần mở rộng file
    switch (extension) {
      case 'txt':
        return 'bg-gray-500';
      case 'pdf':
        return 'bg-red-500'; // Màu đỏ cho file PDF
      case 'doc':
      case 'docx':
        return 'bg-blue-500'; // Màu xanh cho file Word
      case 'xlsx':
      case 'xls':
        return 'bg-green-500'; // Màu xanh lá cho file Excel
      // Màu vàng cho file hình ảnh
      // Màu mặc định
    }
  };

  const getFileColor = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'txt':
        return '#6B7280'; // Màu xám nhạt
      case 'doc':
      case 'docx':
        return '#3B82F6'; // Màu xanh lam
      case 'pdf':
        return '#EF4444'; // Màu đỏ
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'svg':
        return '#10B981'; // Màu xanh lá
      default:
        return '#4B5563'; // Màu xám đậm
    }
  };

  if (props.viewMode) {
    if (props.mode === 'form') {
      return (
        <img
          id="display-img"
          src={require('../../Assets/Images/avatar3.png')}
          alt="avatar"
          className={props.className}
        />
      );
    }
    if (props.mode === 'download') {
      return (
        <div className="mt-3 flex">
          {props.files?.map((item, index) => (
            <div key={index} className={`${props.className ? props.className : 'mr-3 h-[141px] w-[240px]'}`}>
              <div className="rounded-lg border">
                <div>
                  {props.uploadKey !== '' && (
                    <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
                  )}
                </div>
                <div>
                  {/* Thay ảnh bằng div với màu sắc đặc trưng */}
                  <div>
                    {/* Kiểm tra nếu là ảnh, hiển thị ảnh; nếu không, hiển thị ô màu đặc trưng */}
                    {isImageFile(item.fileNm) ? (
                      <img
                        id={`img-${props.mode}-${item.fileSeq}`}
                        src={`${backendURL + '/file/displayImg/' + item.fileSeq}`}
                        alt={item.fileNm}
                        className="relative h-[141px] w-[240px]"
                      />
                    ) : (
                      <div
                        id={`img-${props.mode}-${item.fileSeq}`}
                        className={`relative flex h-[141px] w-[240px] items-center justify-center rounded-md ${getFileTypeColor(
                          item.fileNm
                        )}`}
                      >
                        <p
                          style={{
                            whiteSpace: 'normal', // Tự động xuống dòng
                            wordWrap: 'break-word', // Ngắt từ dài
                            wordBreak: 'break-word', // Hỗ trợ xuống dòng nếu từ quá dài
                            maxWidth: '100%', // Đảm bảo không vượt quá giới hạn chiều rộng
                            overflow: 'hidden', // Ẩn phần dư thừa
                            textOverflow: 'ellipsis' // Hiển thị dấu "..."
                          }}
                          className="text-lg font-bold text-white"
                        >
                          {getFileTypeLabel(item.fileNm)}
                        </p>
                      </div>
                    )}

                    <button
                      className="mr-2 flex  w-[240px] items-center justify-center rounded-md bg-white px-4"
                      type="button"
                      onClick={() => handleDownload(index)}
                    >
                      <p
                        style={{
                          whiteSpace: 'normal', // Tự động xuống dòng
                          wordWrap: 'break-word', // Ngắt từ dài
                          wordBreak: 'break-word' // Hỗ trợ xuống dòng nếu từ quá dài
                        }}
                        className="text-xs"
                      >
                        {item.fileNm}
                      </p>
                      <img src={icDispatch} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <img
          id={`img-${props.mode}-${props.seq}`}
          src={require('../../Assets/Images/avatar2.png')}
          alt="GroupWare Logo"
          className="w.5 mr-2 h-[48px] w-[48px] rounded-full object-cover text-base"
        />
      );
    }
  } else {
    if (props.mode === 'displayWctImg') {
      return (
        <div>
          <div className="">
            {props.uploadKey !== '' && (
              <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
            )}
            <div className="upload-btn flex items-center rounded-[6px] bg-[#EDF5F5] p-[8px] text-[12px] font-[500] text-[#1294A4]">
              Thay đổi <img src={ChangeAvatarIcon} className="ml-[6px]" />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-4 gap-2">
          <div className="flex gap-2">
            <div className="flex flex-col justify-items-start">
              {/* Hiển thị trường uploadKey chỉ khi có uploadKey */}
              {props.uploadKey !== '' && (
                <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />
              )}

              {/* Nút tải lên và hướng dẫn về loại tệp */}
              <button
                type="button"
                className="upload-btn flex h-[120px] w-[200px] items-center justify-center rounded-[6px] border-2 border-dashed border-[#9C9C9C] bg-[#F2F2F2] p-[8px] text-center text-[12px] font-[500] text-black"
              >
                <div>
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="m-auto"
                  >
                    <path
                      d="M16.5 12H8.5M12.5 16V8M1.5 12C1.5 18.0751 6.42487 23 12.5 23C18.5751 23 23.5 18.0751 23.5 12C23.5 5.92487 18.5751 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12Z"
                      stroke="#171717"
                      strokeLinecap="round"
                    />
                  </svg>
                  <p className="pt-2">
                    File {t('job.support')}: .txt, .docx, .doc, .pdf, .png, .jpg, .svg
                    <br /> {t('job.maximum-capacity')} 10mb
                  </p>
                </div>
              </button>
              <p>{props.fileNm}</p>
            </div>
            {/* Hiển thị các tệp đã tải lên */}
            <div className="grid grid-cols-4 gap-x-56 gap-y-5">
              {props.files?.map((item, index) => {
                const checkFileImage = isImageFile(item.fileNm); // Kiểm tra xem có phải là file ảnh không
                const checkFileText = isTextFile(item.fileNm); // Kiểm tra xem có phải là file text không
                return (
                  <div className=" " key={index}>
                    <div className="relative h-[120px] w-[200px] rounded-md border">
                      {/* Nút xóa file */}
                      <button type="button" onClick={() => handleRemove(index)} className="absolute p-2">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="rounded-full bg-gray-500 p-1"
                        >
                          <path d="M12 4L4 12M12 12L4 4.00001" stroke="white" strokeLinecap="round" />
                        </svg>
                      </button>
                      <div>
                        {/* Hiển thị ảnh, file text hoặc file khác */}
                        {checkFileImage ? (
                          <img
                            id={`img-${props.mode}-${item.seq}`}
                            src={`${backendURL + '/file/displayImg/' + item.seq}`}
                            alt="File Image"
                            className="h-[120px] w-[200px] rounded-md border"
                          />
                        ) : checkFileText ? (
                          <div
                            className="flex h-[120px] w-[200px] items-center justify-center rounded-xl border font-semibold uppercase text-white"
                            style={{
                              backgroundColor: getFileColor(item.fileNm)
                            }}
                          >
                            {item.fileNm.split('.').pop().toLowerCase()} (Text File)
                          </div>
                        ) : (
                          <div
                            className="flex h-[120px] w-[200px] items-center justify-center rounded-xl border font-semibold uppercase text-white"
                            style={{
                              backgroundColor: getFileColor(item.fileNm)
                            }}
                          >
                            {item.fileNm.split('.').pop().toLowerCase()} (Other File)
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }
};

export default DownLoadFile;
