import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const UserAvatar = (props) => {
  const { t } = useTranslation();

  if (props.viewMode) {
    if (props.mode === 'form') {
      return (
        <img
          id="display-img"
          src={require('../../Assets/Images/avatar3.png')}
          alt="avatar"
          className={props.className}
        />
      );
    } else {
      return (
        <img
          id={`img-${props.mode}-${props.seq}`}
          src={require('../../Assets/Images/avatar3.png')}
          alt="avatar"
          className={props.className}
        />
      );
    }
  } else {
    return (
      <div>
        {props.uploadKey !== '' && <input type="hidden" {...props.register('uploadKey', { value: props.uploadKey })} />}
        <div className=" w-[170px] rounded-md bg-white p-2 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)]">
          <button
            type="button"
            className="upload-btn w-full rounded-md   p-[8px] text-left  text-[13px] font-[500] hover:bg-gray-200 "
          >
            {t('project.download-profile-photo')}
          </button>
          {props.file?.length ? (
            <button
              type="button"
              className="w-full rounded-md  p-[8px] text-left text-[13px] font-[500] hover:bg-gray-200"
              onClick={async () => {
                toast.warning(
                  <span
                    onClick={async () => {
                      await props.handleRemove(0);
                    }}
                    className="cursor-pointer text-sm font-medium hover:text-yellow-400"
                  >
                    {t('user-avatar.confirm-photo-removal')}
                  </span>,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'cursor-default',
                    pauseOnHover: true,
                    autoClose: 3000
                  }
                );
              }}
            >
              {t('project.delete-profile-photo')}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
};

export default UserAvatar;
