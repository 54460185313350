// import { useForm } from 'react-hook-form';
// import AttachFile from '../../Components/AttachFile/AttachFile';

import { Outlet } from 'react-router-dom';

import SideBarProject from './SideBarProject';

const ProjectManagement = () => {
  return (
    <div className="flex">
      <SideBarProject />
      <div className=" w-full">
        <Outlet />
      </div>
    </div>
  );
};
export default ProjectManagement;
