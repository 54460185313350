/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import projectBoard from '../../../../../Apis/ProjectManagement/TaskProgress/board';
import requestClient from '../../../../../Apis/requestClient';
import backendURL from '../../../../../config';
import { useGlobalContext } from '../../../../../Utils/Context';
import ConvertTime from '../../../../../Utils/ConvertTime';
import AttachFile from '../../../../AttachFile/AttachFile';
import { Button } from '../../../../Buttons/ButtonComponent';
import Icon from '../../../../Common/Icon';
import { handleCheckSpaces, handleCheckTagName } from './CheckComment';
import CommentQuill from './CommentQuill';

const Comment = ({ className, projBoardTaskDetail, showActivity, setDetailTask, dataFilelink, projectInfo }) => {
  const { bzStomp, userInfo, changeIdUserInfomation } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const { boardId } = useParams();
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [currentOpenParentId, setCurrentOpenParentId] = useState(null);
  const [isComment, setIsComment] = useState(false);
  const [isCommentRelies, setIsCommentRelies] = useState(false);
  const [openConfirmDeleteComment, setOpenConfirmDeleteComment] = useState(false);
  const [openConfirmDeleteRelies, setOpenConfirmDeleteRelies] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [dataSocketUpdate, setDataSocketUpdate] = useState(null);
  const [isSocket, setIsSocket] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [replyText, setReplyText] = useState('');
  const [replies, setReplies] = useState([]);
  const [comment, setComment] = useState('');
  const [isOperation, setIsOperation] = useState(false);
  const [isOperationRelies, setIsOperationRelies] = useState(false);
  const [commentId, setCommentId] = useState('');
  const [reliesId, setReliesIdId] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [reactionId, setReactionId] = useState('');
  const [isUpdateComment, setIsUpdateComment] = useState(false);
  const [isUpdateRelies, setIsUpdateRelies] = useState(false);
  const [commentUpdate, setCommentUpdate] = useState('');
  const [reliesUpdate, setReliesUpdate] = useState('');
  const [showMembers, setShowMembers] = useState(false);
  const [openReactionComment, setOpenReactionComment] = useState(false);
  const [cursorPositionBeforeList, setCursorPositionBeforeList] = useState(null);
  const [lengthValueComment, setLengthValueComment] = useState(0);
  const [lengthValueCommentReplies, setLengthValueCommentReplies] = useState(0);
  const commentQuillRef = useRef(null);
  const RepiesQuillRef = useRef(null);
  const commentQuillUpdateRef = useRef();
  const reliesQuillUpdateRef = useRef();
  // Handle comment
  // eslint-disable-next-line no-unused-vars
  const [memberList, setMemberList] = useState([]);
  const [memberProject, setMemberProject] = useState(null);
  const [tagUserIdList, setTagUserIdList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const handleChange = (value, delta, source, editor) => {
    setLengthValueComment(value?.length);
    const atIndex = value.lastIndexOf('@');
    const spaceIndex = value.lastIndexOf(' ');
    setCursorPositionBeforeList(editor?.getSelection()?.index);

    // Tạo một đối tượng DOMParser để check lần đầu đẩy ảnh
    var parser = new DOMParser();
    var doc = parser.parseFromString(value, 'text/html');

    // Thay thế tất cả thẻ <p> bằng <div>
    doc.querySelectorAll('p').forEach((p) => {
      const div = document.createElement('div');
      div.innerHTML = p.innerHTML;
      p.replaceWith(div);
    });

    // Loại bỏ tất cả thẻ <br> trong nội dung
    doc.querySelectorAll('br').forEach((br) => {
      br.remove(); // Loại bỏ các thẻ <br>
    });

    // Sau khi thay thế và xử lý, lấy lại nội dung HTML đã thay đổi
    value = doc.body.innerHTML;

    const fileExtensions = [
      '.xlsx',
      '.ppt',
      '.pptx',
      '.txt',
      '.csv',
      '.docx',
      '.doc',
      '.pdf',
      '.png',
      '.jpg',
      '.jpeg',
      '.zip',
      '.rar'
    ];

    // Kiểm tra văn bản để tìm các file
    const regex = new RegExp(`(\\S+(${fileExtensions.join('|').replace(/\./g, '\\.')})$)`, 'g');
    const matches = value.match(regex);
    if (matches) {
      const formattedValue = matches.join('<br/>');
      setComment(formattedValue);
      return; // Chặn không cho tiếp tục
    }

    var hasImgTag = doc.querySelector('img') !== null;

    // Kiểm tra xem có ảnh Base64 trong nội dung không
    const hasBase64Image = /<img[^>]+src=["']data:image\/(jpeg|png|gif|bmp|webp);base64,[^"']*["'][^>]*>/g.test(value);
    if (hasBase64Image) {
      alert(t('project.error-comments'));
      return;
    }

    const quill = commentQuillRef.current.getEditor();
    const beforKeyUp = quill.getText(editor?.getSelection()?.index - 2, 1);
    const afterKeyUp = quill.getText(editor?.getSelection()?.index, 1);
    const currentKeyUp = quill.getText(editor?.getSelection()?.index - 1, 1);

    if (currentKeyUp === ' ') {
      setShowMembers(false);
    } else {
      if (beforKeyUp === ' ' && afterKeyUp === ' ' && currentKeyUp === '@') {
        setShowMembers(true);
        reSearchMember();
      } else if (atIndex > spaceIndex) {
        setShowMembers(true);
        reSearchMember();
      } else {
        setShowMembers(false);
      }
    }

    const text = quill.getText().trim();
    const isEmpty = handleCheckSpaces(text);
    const searchName = handleCheckTagName(delta);

    if (isEmpty && !hasImgTag) {
      setTagUserIdList([]);
      setComment('');
    } else {
      setComment(value);
    }

    if (searchName === 'all') {
      reSearchMember();
    }
  };

  const handleChangeReplies = (value, delta, source, editor) => {
    setLengthValueCommentReplies(value?.length); // Cập nhật số lượng ký tự trong input phản hồi
    const atIndex = value.lastIndexOf('@');
    const spaceIndex = value.lastIndexOf(' ');
    setCursorPositionBeforeList(editor?.getSelection()?.index);

    // Sử dụng DOMParser để kiểm tra nội dung lần đầu khi có hình ảnh
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');

    // Danh sách các file cần kiểm tra
    const fileExtensions = [
      '.xlsx',
      '.ppt',
      '.pptx',
      '.txt',
      '.csv',
      '.docx',
      '.doc',
      '.pdf',
      '.png',
      '.jpg',
      '.jpeg',
      '.zip',
      '.rar'
    ];

    // Tìm các file trong nội dung
    const regex = new RegExp(`(\\S+(${fileExtensions.join('|').replace(/\./g, '\\.')})$)`, 'g');
    const matches = value.match(regex);
    if (matches) {
      const formattedValue = matches.join('<br/>');
      setReplyText(formattedValue); // Định dạng danh sách file và đặt vào replyText
      return; // Ngăn không cho tiếp tục nếu có file
    }

    // Kiểm tra thẻ <img> và ảnh Base64 trong nội dung
    const hasImgTag = doc.querySelector('img') !== null;
    const hasBase64Image = /<img[^>]+src=["']data:image\/(jpeg|png|gif|bmp|webp);base64,[^"']*["'][^>]*>/g.test(value);

    if (hasBase64Image) {
      alert(t('project.error-comments')); // Hiển thị lỗi khi có ảnh Base64
      return; // Ngăn không cho tiếp tục
    }

    // Kiểm tra xem nội dung có rỗng không
    if (String(value) === '<p><br></p>' || (value.slice(2, -4).trim() === '<p></p>' && !hasImgTag)) {
      setReplyText(''); // Xóa nội dung nếu chỉ có khoảng trắng
      return;
    } // Thay thế tất cả thẻ <p> bằng <div>
    doc.querySelectorAll('p').forEach((p) => {
      const div = document.createElement('div');
      div.innerHTML = p.innerHTML;
      p.replaceWith(div);
    });

    // Loại bỏ tất cả thẻ <br> trong nội dung
    doc.querySelectorAll('br').forEach((br) => {
      br.remove(); // Loại bỏ các thẻ <br>
    });

    // Sau khi thay thế và xử lý, lấy lại nội dung HTML đã thay đổi
    value = doc.body.innerHTML;

    // Xử lý sự kiện @ để hiển thị danh sách thành viên
    const quill = RepiesQuillRef.current.getEditor();
    const beforeKeyUp = quill.getText(editor?.getSelection()?.index - 2, 1);
    const afterKeyUp = quill.getText(editor?.getSelection()?.index, 1);
    const currentKeyUp = quill.getText(editor?.getSelection()?.index - 1, 1);

    if (currentKeyUp === ' ') {
      setShowMembers(false); // Ẩn danh sách thành viên khi nhấn khoảng trắng
    } else {
      if (beforeKeyUp === ' ' && afterKeyUp === ' ' && currentKeyUp === '@') {
        setShowMembers(true);
        reSearchMember(); // Tìm kiếm thành viên
      } else if (atIndex > spaceIndex) {
        setShowMembers(true);
        reSearchMember();
      } else {
        setShowMembers(false);
      }
    }

    const text = quill.getText().trim();
    const isEmpty = handleCheckSpaces(text); // Kiểm tra nội dung trống
    const searchName = handleCheckTagName(delta);

    // Kiểm tra và cập nhật nội dung của phản hồi
    if (isEmpty && !hasImgTag) {
      setTagUserIdList([]);
      setReplyText('');
    } else {
      setReplyText(value);
    }

    if (searchName === 'all') {
      reSearchMember();
    }
  };
  const handleToggleReplies = (item) => {
    const itemId = item.projBoardTaskActiDTO.projBoardTaskActiId;

    // Lấy số lượng phản hồi hiện có cho bình luận cha này
    const replyCount = replies.filter((reply) => reply.projBoardTaskActiDTO.activityParentId === itemId).length;

    // Nếu đang mở phần trả lời cho bình luận này
    if (currentOpenParentId === itemId) {
      // Đóng phần trả lời nếu bình luận đã mở
      const updatedCommentList = commentList.map((comment) => {
        if (comment.projBoardTaskActiDTO.projBoardTaskActiId === itemId) {
          return {
            ...comment,
            projBoardTaskActiDTO: {
              ...comment.projBoardTaskActiDTO,
              totalReplies: replyCount // Cập nhật số lượng phản hồi khi đóng
            }
          };
        }
        return comment;
      });

      setCommentList(updatedCommentList); // Cập nhật lại commentList
      setCurrentOpenParentId(null); // Đóng phần trả lời
      setShowReplyInput(false); // Đóng ô nhập phản hồi
    } else {
      // Nếu chưa có phản hồi nào thì gọi hàm lấy tất cả phản hồi
      if (replyCount === 0) {
        getAllRepliesList(itemId, 0); // Gọi API để lấy phản hồi nếu chưa có
      }

      // Mở phần trả lời cho bình luận này và cập nhật totalReplies
      const updatedCommentList = commentList.map((comment) => {
        if (comment.projBoardTaskActiDTO.projBoardTaskActiId === itemId) {
          return {
            ...comment,
            projBoardTaskActiDTO: {
              ...comment.projBoardTaskActiDTO,
              totalReplies: replyCount // Cập nhật số lượng phản hồi khi mở
            }
          };
        }
        return comment;
      });

      setCommentList(updatedCommentList); // Cập nhật lại commentList
      setCurrentOpenParentId(itemId); // Mở phần trả lời cho bình luận này
      // Đóng input trả lời ban đầu
      setShowReplyInput(true);
    }
  };

  const handleReplyClick = (item) => {
    const itemId = item.projBoardTaskActiDTO.projBoardTaskActiId;

    // Mở phần trả lời và danh sách phản hồi khi nhấn vào "Trả lời"
    setCurrentOpenParentId(itemId);
    setShowReplyInput(true); // Hiển thị ô input để nhập phản hồi

    // Gọi hàm lấy tất cả phản hồi nếu chưa có phản hồi nào
    const replyCount = replies.filter((reply) => reply.projBoardTaskActiDTO.activityParentId === itemId).length;
    if (replyCount === 0) {
      getAllRepliesList(itemId, 0);
    }
    setTimeout(() => {
      if (RepiesQuillRef.current) {
        RepiesQuillRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100); // Delay ngắn để đảm bảo DOM đã render
  };
  const handleReplySubmit = async (parentCommentId) => {
    if (replyText) {
      try {
        const body = {
          userID: userInfo?.userID,
          projBoardTaskId: projBoardTaskDetail?.projBoardTaskId,
          content: replyText,
          taskName: projBoardTaskDetail?.taskName,
          userListId: tagUserIdList?.map((user) => user?.userId).toString(),
          activityParentId: parentCommentId // Use the ID of the parent comment or null
        };
        setIsCommentRelies(false);
        await requestClient({
          endPoint: `/boardTaskActivity/createComment?boardId=${boardId}`,
          method: 'post',
          body: body
        });

        // Reset states after submitting
        setReplyText('');
        setLengthValueCommentReplies(0);
        // Reset parentCommentId to null after the reply
      } catch (error) {
        console.log(error);
      }
    }
  };
  // Create new comment
  const onSubmit = async () => {
    if (comment) {
      try {
        const body = {
          userID: userInfo?.userID,
          projBoardTaskId: projBoardTaskDetail?.projBoardTaskId,
          content: comment,
          taskName: projBoardTaskDetail?.taskName,
          userListId: tagUserIdList?.map((user) => user?.userId).toString(),
          activityParentId: null // Add this line to set activityParentId to null
        };
        setIsComment(false);
        await requestClient({
          endPoint: `/boardTaskActivity/createComment?boardId=${boardId}`,
          method: 'post',
          body: body
        });
        setComment('');
        setTagUserIdList([]);
        setLengthValueComment(0);
      } catch (error) {
        console.log(error);
      }
    }
  };
  // ***

  // R_all - get comment list
  const [paging, setPaging] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const getAllRepliesList = async (currentOpenParentId) => {
    if (projBoardTaskDetail?.projBoardTaskId) {
      try {
        setIsLoading(true);
        const endpoint = `/boardTaskActivity/getAllReplies/${projBoardTaskDetail?.projBoardTaskId}?parent=${currentOpenParentId}&page=0&size=100`;
        const res = await requestClient({
          endPoint: endpoint,
          method: 'get'
        });

        const data = res?.data;
        setReplies(data?.data?.replies);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching replies:', error);
        setIsLoading(false);
      }
    }
  };

  const getCommentList = async () => {
    if (projBoardTaskDetail?.projBoardTaskId) {
      try {
        setIsLoading(true);
        let data;
        if (showActivity) {
          const res = await requestClient({
            endPoint: `/projBoardTaskHistoryActi/projTaskHistoryActi/${projBoardTaskDetail?.projBoardTaskId}?page=${paging}&size=10`,
            method: 'get'
          });
          data = res?.data;
          if (paging > 0) {
            setActivityList(activityList?.concat(data?.taskActivity));
          } else {
            setActivityList(data?.taskActivity);
          }
        } else {
          const res = await requestClient({
            endPoint: `/boardTaskActivity/getAllCommentPaging/${projBoardTaskDetail?.projBoardTaskId}?page=${paging}&size=10`,
            method: 'get'
          });
          data = res?.data;
          if (paging > 0) {
            setCommentList(commentList?.concat(data?.comments));
          } else {
            setCommentList(data?.comments);
          }
        }
        setTotalPages(data?.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };
  // useEffect để gọi API khi currentOpenParentId thay đổi
  useEffect(() => {
    if (currentOpenParentId) {
      getCommentList();
      getAllRepliesList();
    }
  }, [currentOpenParentId]);
  useEffect(() => {
    setPaging(0);
  }, [showActivity]);

  const handleShowMore = () => {
    setPaging(paging + 1);
  };

  const modal = document.querySelector('.ReactModal__Overlay--after-open');
  if (modal) {
    modal?.addEventListener('scroll', function (event) {
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (paging <= totalPages && bottom) {
        // return paging(paging + 1);
      }
    });
  }

  useEffect(() => {
    setPaging(0);
  }, [showActivity]);

  const handleTagName = (userId, fullName) => {
    let quill;

    if (isComment) {
      quill = commentQuillRef?.current?.getEditor();
    } else if (isCommentRelies && RepiesQuillRef?.current) {
      // Kiểm tra điều kiện `isReplyComment` và `RepiesQuillRef` có tồn tại
      quill = RepiesQuillRef.current.getEditor();
    } else if (commentQuillUpdateRef?.current) {
      // Kiểm tra điều kiện `commentQuillUpdateRef` có tồn tại
      quill = commentQuillUpdateRef.current.getEditor();
    } else {
      quill = null;
    }

    // Đảm bảo `quill` không phải là `null` trước khi thực hiện các thao tác
    if (quill) {
      quill.pasteHTML(cursorPositionBeforeList, '_');
      quill.deleteText(cursorPositionBeforeList, 1);
      quill.insertText(cursorPositionBeforeList, `${fullName}`);
      quill.formatText(cursorPositionBeforeList - 1, cursorPositionBeforeList + fullName?.length, {
        italic: true
      });
      quill.pasteHTML(cursorPositionBeforeList + fullName?.length, '_');

      // Set style css cho thẻ tag của người dùng
      let [leaf] = quill.getLeaf(cursorPositionBeforeList + 1);
      const insertedElement = leaf.parent.domNode;
      insertedElement.contentEditable = 'false';
      insertedElement.id = 'tagUserId';

      quill.insertText(cursorPositionBeforeList + fullName?.length, ` `);
      quill.deleteText(cursorPositionBeforeList + fullName?.length + 1, 1);

      // Thêm vào `tagUserIdList` nếu `userId` chưa tồn tại
      if (!tagUserIdList.some((item) => item.userId === userId)) {
        setTagUserIdList((item) => [{ userId: userId, tagName: fullName }, ...item]);
      }
    }
  };

  const handleKeyDown = (event) => {
    const arrowKeys = ['ArrowLeft', 'ArrowRight'];

    // Kiểm tra nếu phím nhấn là một trong các phím mũi tên hoặc phím trống
    if (event.key === '' || arrowKeys.includes(event.key)) {
      setShowMembers(false);
    }

    // Xác định Quill editor dựa trên các điều kiện `isComment` và `RepiesQuillRef`
    let quill;
    if (isCommentRelies && RepiesQuillRef?.current) {
      // Kiểm tra điều kiện `isCommentRelies` và `RepiesQuillRef` có tồn tại
      quill = RepiesQuillRef.current.getEditor();
    } else if (isComment && commentQuillRef?.current) {
      // Kiểm tra điều kiện `isComment` và `commentQuillRef` có tồn tại
      quill = commentQuillRef.current.getEditor();
    } else if (commentQuillUpdateRef?.current) {
      // Kiểm tra điều kiện `commentQuillUpdateRef` có tồn tại
      quill = commentQuillUpdateRef.current.getEditor();
    } else {
      quill = null; // Gán `null` nếu không có editor nào phù hợp
    }

    // Kiểm tra sự tồn tại của `quill` và `selectionIndex` trước khi thao tác
    const selectionIndex = quill?.getSelection()?.index;
    if (quill && selectionIndex !== null && selectionIndex !== undefined) {
      const [leaf, offset] = quill.getLeaf(selectionIndex);

      // Nếu phím bấm là 'Backspace' và phần tử hiện tại là thẻ tag `<em>`, xử lý xóa thẻ tag
      if (event.key === 'Backspace' && leaf?.parent?.domNode?.tagName === 'EM') {
        const userTagName = leaf.text.replace('@', '');

        // Cập nhật danh sách `tagUserIdList` để loại bỏ người dùng với tagName đã xóa
        setTagUserIdList((prevTagUserIdList) => prevTagUserIdList.filter((item) => item.tagName !== userTagName));

        // Xóa nội dung thẻ tag trong editor
        quill.deleteText(selectionIndex - leaf.text.length, offset);
      }
    }
  };

  const reSearchMember = async () => {
    // Get list user by username tag
    try {
      const res = await requestClient({
        endPoint: `/projBoardTaskMem/allMemberTask?boardTaskId=${projBoardTaskDetail?.projBoardTaskId}`,
        method: 'get'
      });
      setMemberList(
        res?.data?.dataList?.filter((member) => !tagUserIdList.some((tagUser) => member.userId === tagUser.userId))
      );

      let userObject = res?.data?.dataList?.find((item) => item.userId === userInfo?.userID);
      if (userObject) {
        setMemberProject(userObject);
        return;
      }
      const resNew = await requestClient({
        endPoint: `/member/getMembersProject?page=0&size=100&projectId=${projectInfo?.projectId}`,
        method: 'get'
      });
      setMemberProject(resNew?.data?.members?.find((item) => item.userId === userInfo?.userID));
    } catch (error) {
      console.log(error);
    }
  };
  // ***
  const toggleReplyInput = (parentCommentId) => {
    if (currentOpenParentId === parentCommentId) {
      // Nếu đang mở comment này, thì ẩn input trả lời
      setShowReplyInput(!showReplyInput);
    } else {
      // Mở comment khác và ẩn input trả lời
      setCurrentOpenParentId(parentCommentId);
      setShowReplyInput(true); // Mở input trả lời
    }
  };
  // Update - Delete (popup)
  const handleOperationRelies = (id) => {
    if (id === reliesId) {
      setIsOperationRelies(!isOperationRelies);
      setReliesIdId(id);
    } else {
      setIsUpdateRelies(false);
      setIsOperationRelies(true);
      setReliesIdId(id);
    }
  };
  const handleOperation = (id) => {
    if (id === commentId) {
      setIsOperation(!isOperation);
      setCommentId(id);
    } else {
      setIsUpdateComment(false);
      setIsOperation(true);
      setCommentId(id);
    }
  };
  // Handle update comment
  // eslint-disable-next-line no-unused-vars
  const handleUpdateComment = (value, delta, source, editor) => {
    const atIndex = value.lastIndexOf('@');
    const spaceIndex = value.lastIndexOf(' ');
    setCursorPositionBeforeList(editor?.getSelection()?.index);

    if (String(value) === '<p><br></p>' || value.slice(2, -4).trim() === '<p></p>') {
      setCommentUpdate('');
      return;
    }

    const quill = commentQuillUpdateRef?.current.getEditor();
    const beforKeyUp = quill.getText(editor?.getSelection()?.index - 2, 1);
    // eslint-disable-next-line no-unused-vars
    const afterKeyUp = quill.getText(editor?.getSelection()?.index, 1); // Check key
    const currentKeyUp = quill.getText(editor?.getSelection()?.index - 1, 1);

    if (currentKeyUp === ' ') {
      setShowMembers(false);
    } else {
      if (beforKeyUp === ' ' && afterKeyUp === ' ' && currentKeyUp === '@') {
        // if (beforKeyUp === ' ' && currentKeyUp === '@') {
        setShowMembers(true);
        reSearchMember();
      } else if (atIndex > spaceIndex) {
        setShowMembers(true);
        reSearchMember();
      } else {
        setShowMembers(false);
      }
    }

    const text = quill.getText().trim();
    const isEmpty = handleCheckSpaces(text); // Check input value empty
    if (isEmpty) {
      setCommentUpdate('');
      setTagUserIdList([]);
      return;
    }

    setCommentUpdate(value);
  };
  const handleUpdateRelies = (value, delta, source, editor) => {
    const atIndex = value.lastIndexOf('@');
    const spaceIndex = value.lastIndexOf(' ');
    setCursorPositionBeforeList(editor?.getSelection()?.index);

    if (String(value) === '<p><br></p>' || value.slice(2, -4).trim() === '<p></p>') {
      setReliesUpdate('');
      return;
    }

    const quill = reliesQuillUpdateRef?.current.getEditor();
    const beforKeyUp = quill.getText(editor?.getSelection()?.index - 2, 1);
    // eslint-disable-next-line no-unused-vars
    const afterKeyUp = quill.getText(editor?.getSelection()?.index, 1); // Check key
    const currentKeyUp = quill.getText(editor?.getSelection()?.index - 1, 1);

    if (currentKeyUp === ' ') {
      setShowMembers(false);
    } else {
      if (beforKeyUp === ' ' && afterKeyUp === ' ' && currentKeyUp === '@') {
        // if (beforKeyUp === ' ' && currentKeyUp === '@') {
        setShowMembers(true);
        reSearchMember();
      } else if (atIndex > spaceIndex) {
        setShowMembers(true);
        reSearchMember();
      } else {
        setShowMembers(false);
      }
    }

    const text = quill.getText().trim();
    const isEmpty = handleCheckSpaces(text); // Check input value empty
    if (isEmpty) {
      setReliesUpdate('');
      setTagUserIdList([]);
      return;
    }

    setReliesUpdate(value);
  };

  const onSetValueQuillUpdate = (valueContent) => {
    var arrUserListId = valueContent?.userList?.map((user) => {
      return { userId: user?.userID, tagName: user?.fullName };
    });

    // Tạo một div tạm thời để chứa cú pháp HTML
    setTagUserIdList(arrUserListId);

    if (commentQuillUpdateRef.current) {
      const quill = commentQuillUpdateRef.current.getEditor();
      // Lấy ra DOM node của Quill
      const quillDOMNode = quill?.container;
      // Set style css for tag member element
      quill.pasteHTML(0, showActivity ? valueContent?.content : valueContent?.projBoardTaskActiDTO?.content);
      // Lấy danh sách tất cả các phần tử <em> bên trong <p>
      const emElements = quillDOMNode.querySelectorAll('em');
      // Duyệt qua từng phần tử <em> và thực hiện các thay đổi
      emElements.forEach((em) => {
        em.contentEditable = 'false';
        em.id = 'tagUserId';
      });
      const emFileElements = quillDOMNode.querySelectorAll('a em');
      emFileElements.forEach((em) => {
        em.contentEditable = 'false';
        em.id = 'linkUrlfile';
      });
      quill.focus();
    }
  };
  const onSetValueQuillUpdateRelies = (valueContent) => {
    var arrUserListId = valueContent?.userList?.map((user) => {
      return { userId: user?.userID, tagName: user?.fullName };
    });

    // Tạo một div tạm thời để chứa cú pháp HTML
    setTagUserIdList(arrUserListId);

    if (reliesQuillUpdateRef.current) {
      const quill = reliesQuillUpdateRef.current.getEditor();
      // Lấy ra DOM node của Quill
      const quillDOMNode = quill?.container;
      // Set style css for tag member element
      quill.pasteHTML(0, showActivity ? valueContent?.content : valueContent?.projBoardTaskActiDTO?.content);
      // Lấy danh sách tất cả các phần tử <em> bên trong <p>
      const emElements = quillDOMNode.querySelectorAll('em');
      // Duyệt qua từng phần tử <em> và thực hiện các thay đổi
      emElements.forEach((em) => {
        em.contentEditable = 'false';
        em.id = 'tagUserId';
      });
      const emFileElements = quillDOMNode.querySelectorAll('a em');
      emFileElements.forEach((em) => {
        em.contentEditable = 'false';
        em.id = 'linkUrlfile';
      });
      quill.focus();
    }
  };

  // U
  const onSubmitUpdate = async (projBoardTaskActiId) => {
    try {
      const body = {
        projBoardTaskId: projBoardTaskActiId,
        content: commentUpdate,
        userListId: tagUserIdList
          ?.map((user) => {
            return user?.userId;
          })
          .toString()
      };
      setIsUpdateComment(false);
      await requestClient({
        endPoint: `/boardTaskActivity/updateComment/${projBoardTaskActiId}?boardId=${boardId}`,
        method: 'put',
        body: body
      });
      setCommentUpdate('');
      setLengthValueComment(0);
      // getCommentList();
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmitUpdateRelies = async (projBoardTaskActiId) => {
    try {
      const body = {
        projBoardTaskId: projBoardTaskActiId,
        content: reliesUpdate,
        userListId: tagUserIdList
          ?.map((user) => {
            return user?.userId;
          })
          .toString()
      };
      setIsUpdateRelies(false);
      await requestClient({
        endPoint: `/boardTaskActivity/updateComment/${projBoardTaskActiId}?boardId=${boardId}`,
        method: 'put',
        body: body
      });
      setReliesUpdate('');
      setLengthValueCommentReplies(0);
      // getCommentList();
    } catch (error) {
      console.log(error);
    }
  };

  // Delete comment
  const handleDelete = async (projBoardTaskActiId) => {
    try {
      await requestClient({
        endPoint: `/boardTaskActivity/deleteComment/${projBoardTaskActiId}?boardId=${boardId}`,
        method: 'delete'
      });
      setOpenConfirmDeleteComment(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteRelies = async (projBoardTaskActiId) => {
    try {
      await requestClient({
        endPoint: `/boardTaskActivity/deleteComment/${projBoardTaskActiId}?boardId=${boardId}`,
        method: 'delete'
      });
      setOpenConfirmDeleteRelies(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (bzStomp.connected) {
      bzStomp.subscribe('/user/topic/history-acti-task', (res) => {
        // The socket receives the signal and retrieves the comment list
        const response = JSON.parse(res.body);
        if (response?.projBoardTaskId !== projBoardTaskDetail?.projBoardTaskId) {
          return;
        }
        setDataSocketUpdate(response);
        setIsSocket(true);
      });
    }
  }, [bzStomp.connected]);

  useEffect(() => {
    const reloadCommentAndActivity = () => {
      const isReply = !!dataSocketUpdate?.projBoardTaskActiDTO?.activityParentId;

      if (dataSocketUpdate?.flag === 'D') {
        // Xóa bình luận hoặc phản hồi khi nhận tín hiệu "D" (xóa)
        setCommentList(
          commentList?.filter(
            (comment) =>
              comment?.projBoardTaskActiDTO?.projBoardTaskActiId !== dataSocketUpdate?.projBoardTaskActiId &&
              comment?.projBoardTaskActiDTO?.activityParentId !==
                dataSocketUpdate?.projBoardTaskActiDTO?.activityParentId
          )
        );
        setReplies(
          replies?.filter(
            (reply) => reply?.projBoardTaskActiDTO?.projBoardTaskActiId !== dataSocketUpdate?.projBoardTaskActiId
          )
        );
        return;
      }

      if (showActivity) {
        // Nếu cần hiển thị hoạt động (activity), thêm vào danh sách activityList
        setActivityList([{ ...dataSocketUpdate, content: null }, ...activityList]);
      } else if (!showActivity && dataSocketUpdate?.type !== 'activity') {
        if (isReply) {
          // Đây là một phản hồi, kiểm tra xem đã tồn tại hay chưa
          const existingReplyIndex = replies.findIndex(
            (reply) =>
              reply?.projBoardTaskActiDTO?.projBoardTaskActiId ===
              dataSocketUpdate?.projBoardTaskActiDTO?.projBoardTaskActiId
          );

          if (existingReplyIndex !== -1) {
            // Nếu phản hồi đã tồn tại, cập nhật nó
            const updatedReplies = [...replies];
            updatedReplies[existingReplyIndex] = dataSocketUpdate;
            setReplies(updatedReplies);
          } else {
            // Nếu phản hồi mới, thêm vào danh sách replies
            setReplies((prevReplies) => [...prevReplies, dataSocketUpdate]);
          }

          // Cập nhật bình luận cha với phản hồi đã cập nhật hoặc thêm mới
          const parentCommentId = dataSocketUpdate.projBoardTaskActiDTO.activityParentId;
          const updatedCommentList = commentList.map((comment) => {
            if (comment.projBoardTaskActiDTO.projBoardTaskActiId === parentCommentId) {
              const updatedRepliesInComment = comment.replies ? [...comment.replies] : [];
              const existingReplyIndexInComment = updatedRepliesInComment.findIndex(
                (reply) =>
                  reply.projBoardTaskActiDTO.projBoardTaskActiId ===
                  dataSocketUpdate.projBoardTaskActiDTO.projBoardTaskActiId
              );

              if (existingReplyIndexInComment !== -1) {
                // Nếu phản hồi đã tồn tại trong bình luận cha, cập nhật nó
                updatedRepliesInComment[existingReplyIndexInComment] = dataSocketUpdate;
              } else {
                // Nếu phản hồi mới, thêm vào danh sách replies của bình luận cha
                updatedRepliesInComment.push(dataSocketUpdate);
              }

              return {
                ...comment,
                replies: updatedRepliesInComment
              };
            }
            return comment;
          });
          setCommentList(updatedCommentList);
        } else {
          // Xử lý bình luận chính
          const existingIndex = commentList?.findIndex(
            (item) =>
              item?.projBoardTaskActiDTO?.projBoardTaskActiId ===
              dataSocketUpdate?.projBoardTaskActiDTO?.projBoardTaskActiId
          );

          if (existingIndex === -1) {
            // Nếu không tìm thấy bình luận, thêm mới
            setCommentList([dataSocketUpdate, ...commentList]);
          } else {
            // Nếu đã có bình luận, thay thế
            const updatedList = [...commentList];
            updatedList[existingIndex] = dataSocketUpdate;
            setCommentList(updatedList);
          }
        }
      }
    };

    if (dataSocketUpdate !== null && isSocket) {
      reloadCommentAndActivity(); // Gọi lại hàm xử lý khi có tín hiệu mới
    }

    // Đặt lại trạng thái khi tín hiệu WebSocket nhận xong
    setDataSocketUpdate(null);
    setIsSocket(false);
  }, [dataSocketUpdate, isSocket, showActivity, commentList, replies]);

  useEffect(() => {
    // Get all comment by boar task Id
    getAllRepliesList();
  }, [projBoardTaskDetail, paging, showActivity]);
  useEffect(() => {
    // Get all comment by boar task Id
    getCommentList();
  }, [projBoardTaskDetail, paging, showActivity]);

  useEffect(() => {
    reSearchMember();
  }, []);

  const listRef = useRef();
  const listRefReplies = useRef();

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      // Check when the member list is open, click on another part and it will automatically close
      setShowMembers(false);
      setMemberList([]);
    }
  };
  const handleClickOutsideReplies = (event) => {
    if (listRefReplies.current && !listRefReplies.current.contains(event.target)) {
      // Check when the member list is open, click on another part and it will automatically close
      setShowMembers(false);
      setMemberList([]);
    }
  };

  const onReactionComment = async (type, id) => {
    try {
      const body = {
        projBoardTaskActiId: id,
        memberId: memberProject?.memberId,
        reaction: type
      };
      await requestClient({
        endPoint: `/boardTaskActivity/reactComment`,
        method: 'post',
        body: body
      });
      setOpenReactionComment(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if ((!isComment && !isUpdateComment) || comment === '' || commentUpdate === '') {
      // Check that when there is no comment content and no comments are being made, reset the tagUserId list
      setTagUserIdList([]);
      setMemberList([]);
    }
  }, [isComment, comment, commentUpdate]);
  useEffect(() => {
    if ((!isCommentRelies && !isUpdateRelies) || replyText === '' || reliesUpdate === '') {
      // Check that when there is no comment content and no comments are being made, reset the tagUserId list
      setTagUserIdList([]);
      setMemberList([]);
    }
  }, [isCommentRelies, replyText, reliesUpdate]);

  useEffect(() => {
    const quill = commentQuillRef?.current?.getEditor();
    const quillUpdate = commentQuillRef?.current?.getEditor();
    if (showMembers) {
      quill?.enable(false);
      quillUpdate?.enable(false);
    } else {
      quill?.enable(true);
      quillUpdate?.enable(true);
      quill?.focus();
      quillUpdate?.focus();
    }
  }, [showMembers]);

  useEffect(() => {
    if (!isUpdateComment) {
      setCommentUpdate('');
    }
  }, [isUpdateComment]);
  useEffect(() => {
    if (!isUpdateRelies) {
      setReliesUpdate('');
    }
  }, [isUpdateRelies]);

  useEffect(() => {
    if (tagUserIdList?.length && (comment !== '' || commentUpdate !== '')) {
      // Check if the comment content contains tagName, if not, delete the untagged userId

      // // Find all <em> tags using regular expression
      const regex = /<em[^>]*>(.*?)<\/em>/g;
      const matches = (commentUpdate || comment).match(regex);
      // Loop through each result and log the starting and ending positions of each <em> tag
      const newTagUserId = [];
      matches?.map((match) => {
        const contentMatch = match.match(/<em[^>]*>(.*?)<\/em>/);
        // Checks if there is a value in the <em> tag
        const content = contentMatch[1].replace('@', '');
        // Check if the value in the <em> tag matches any tagName that does not exist, then add it to the newTagUserId array
        tagUserIdList?.map((user) => {
          if (user.tagName === content) {
            newTagUserId.push(user);
          }
        });
        setTagUserIdList(newTagUserId);
      });
    }
  }, [comment, commentUpdate]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideReplies);
    return () => {
      document.removeEventListener('click', handleClickOutsideReplies);
    };
  }, []);
  //*
  const [dataLink, setDataLink] = useState();
  const getDataLink = async (idLink) => {
    const data = await projectBoard.findProjBoardTaskFileLinkById(idLink);
    setDataLink(data?.data?.data);
  };
  return (
    <div className={className}>
      {/* Create */}
      <div id="top" className="flex items-start gap-x-4">
        <AttachFile
          attachType="UserAvatar"
          entity="users"
          seq={userInfo?.userID}
          viewMode={true}
          mode="user-list-meeting"
          className="h-10 w-10 rounded-full object-cover"
        />
        {isComment ? (
          <div className="relative w-full max-w-[calc(100%-56px)]">
            {showMembers && memberList?.length ? (
              <div
                ref={listRef}
                className="absolute bottom-[calc(100%+16px)] h-48 w-full rounded-lg bg-neu-500 py-6 px-3"
              >
                <div className="font-semibold">{t('department.member')}</div>
                <ul className="max-h-[calc(100%-20px)] overflow-y-auto">
                  {memberList?.map((item, index) => {
                    if (item?.userId !== userInfo?.userID) {
                      return (
                        <li
                          key={index}
                          onClick={() => handleTagName(item?.userId, item?.fullName)}
                          className="flex cursor-pointer items-center justify-between gap-x-2 rounded-lg p-1.5 hover:bg-white"
                        >
                          <div className="flex items-center gap-x-2">
                            <AttachFile
                              attachType="UserAvatar"
                              entity="users"
                              seq={item?.userId}
                              viewMode={true}
                              mode={'comment' + index}
                              className="h-8 w-8 rounded-full object-cover"
                            />
                            <div>{item?.fullName}</div>
                          </div>
                          <div>{item?.userName ? `@${item?.userName}` : ''}</div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            ) : (
              <></>
            )}
            <CommentQuill
              lengthValueComment={lengthValueComment}
              setLengthValueComment={setLengthValueComment}
              dataFilelink={dataFilelink}
              setDetailTask={setDetailTask}
              detailTask={projBoardTaskDetail}
              ref={commentQuillRef}
              setIsComment={setIsComment}
              setComment={setComment}
              comment={comment}
              isEmpty={true}
              handleChange={handleChange}
              onSubmit={onSubmit}
              onKeyDownValue={handleKeyDown}
              theme="snow"
            />
          </div>
        ) : (
          <div
            onClick={async (event) => {
              if (projectInfo?.roleProject === 3) {
                return;
              }
              setComment('');
              await setIsComment(true);
              setIsUpdateComment(false);
              setIsOperation(false);
              if (commentQuillRef.current) {
                commentQuillRef.current.focus();
              }
              event.stopPropagation();
            }}
            className="w-full cursor-text rounded-lg bg-neu-500 p-3 text-sm text-neu-200"
          >
            {t('project.enter-comment')}
          </div>
        )}
      </div>

      {/* Display */}
      {commentList?.length || activityList?.length ? (
        <>
          <ul className="mt-4 flex flex-col gap-y-4 text-sm">
            {!showActivity &&
              commentList?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex w-full items-center justify-between gap-x-4 border-b py-3 last:border-none"
                  >
                    {/* Avatar - content */}
                    <div className="flex w-full flex-col gap-x-4">
                      <div className="flex items-center justify-between gap-4">
                        <div className="flex items-center gap-2 ">
                          <div
                            onClick={() => changeIdUserInfomation(item?.projBoardTaskActiDTO?.userID)}
                            className="cursor-pointer"
                          >
                            <AttachFile
                              attachType="UserAvatar"
                              entity="users"
                              seq={item?.projBoardTaskActiDTO?.userID}
                              viewMode={true}
                              mode={'comment' + index}
                              className="h-10 min-h-[40px] w-10 min-w-[40px] rounded-full object-cover"
                            />
                          </div>
                          <span className="pl-2">{item?.projBoardTaskActiDTO?.fullName}</span>
                          {/* Time */}
                          <div className="flex items-center gap-x-4">
                            <li className="ml-8 min-w-[94px] list-disc text-neu-200">
                              {item?.projBoardTaskActiDTO?.createdAt && (
                                <ConvertTime
                                  type="project"
                                  timeInMileSec={
                                    new Date().getTime() - new Date(item?.projBoardTaskActiDTO?.updatedAt).getTime()
                                  }
                                />
                              )}
                            </li>
                          </div>
                          <div className="relative flex items-center">
                            <button
                              className={`${openReactionComment ? 'focus:ring-2 ' : ''} focus:ring-gray-200`}
                              onClick={(event) => {
                                setReactionId(item?.projBoardTaskActiDTO?.projBoardTaskActiId);
                                setOpenReactionComment(!openReactionComment);
                                event.stopPropagation();
                              }}
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5M6 11.25C6.25 11.75 7.2 12.75 9 12.75C10.8 12.75 11.75 11.75 12 11.25M6.23428 6.98435H6.51553M6.23428 7.2656H6.51553M11.4843 6.98435H11.7655M11.4843 7.2656H11.7655M6.75 7.125C6.75 7.33211 6.58211 7.5 6.375 7.5C6.16789 7.5 6 7.33211 6 7.125C6 6.91789 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.91789 6.75 7.125ZM12 7.125C12 7.33211 11.8321 7.5 11.625 7.5C11.4179 7.5 11.25 7.33211 11.25 7.125C11.25 6.91789 11.4179 6.75 11.625 6.75C11.8321 6.75 12 6.91789 12 7.125Z"
                                  stroke="#9C9C9C"
                                  strokeWidth="0.75"
                                  strokeLinecap="round"
                                />
                                <g clipPath="url(#clip0_8907_16566)">
                                  <path
                                    d="M14.5003 12.168V16.8346M16.8337 14.5013L12.167 14.5013"
                                    stroke="#9C9C9C"
                                    strokeWidth="0.75"
                                    strokeLinecap="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_8907_16566">
                                    <rect width="7" height="7" fill="white" transform="translate(11 11)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </button>
                            {item?.reactionBoardTaskActiDTO?.map((item) => item.userId.length > 0).includes(true) ? (
                              <div className="absolute left-6 -top-2 z-20 flex h-[32px] w-auto scale-[90%] justify-between gap-2 rounded-[24px] border   bg-white px-4 shadow">
                                {item?.reactionBoardTaskActiDTO?.map((reactionComent, key) => {
                                  if (
                                    reactionId === item?.projBoardTaskActiDTO?.projBoardTaskActiId &&
                                    openReactionComment
                                  ) {
                                    return (
                                      <div key={key} className="flex items-center">
                                        <button
                                          onClick={() =>
                                            onReactionComment(
                                              reactionComent?.reaction,
                                              item?.projBoardTaskActiDTO?.projBoardTaskActiId
                                            )
                                          }
                                        >
                                          <span className="flex items-center">
                                            {reactionComent?.reaction === 1 && (
                                              <Icon name="like_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 2 && (
                                              <Icon name="hearth_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 3 && (
                                              <Icon name="smile_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 4 && (
                                              <Icon name="sad_face_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 5 && (
                                              <Icon name="angry_reaction" className="hover:-translate-y-1" />
                                            )}
                                          </span>
                                        </button>
                                        <span className="pl-1 text-base">{reactionComent?.userId?.length}</span>
                                      </div>
                                    );
                                  }
                                  if (reactionComent?.userId?.length) {
                                    return (
                                      <div key={key} className="flex items-center">
                                        <button
                                          onClick={() =>
                                            onReactionComment(
                                              reactionComent?.reaction,
                                              item?.projBoardTaskActiDTO?.projBoardTaskActiId
                                            )
                                          }
                                        >
                                          <span className="flex items-center">
                                            {reactionComent?.reaction === 1 && (
                                              <Icon name="like_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 2 && (
                                              <Icon name="hearth_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 3 && (
                                              <Icon name="smile_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 4 && (
                                              <Icon name="sad_face_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 5 && (
                                              <Icon name="angry_reaction" className="hover:-translate-y-1" />
                                            )}
                                          </span>
                                        </button>
                                        <span className="pl-1 text-base">{reactionComent?.userId?.length}</span>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            ) : (
                              <div
                                className={`${
                                  reactionId === item?.projBoardTaskActiDTO?.projBoardTaskActiId && openReactionComment
                                    ? 'absolute'
                                    : 'hidden'
                                } left-6 -top-2 z-20 flex h-[32px] w-auto scale-[90%] justify-between gap-3 rounded-[24px] border bg-white px-2 shadow`}
                              >
                                {item?.reactionBoardTaskActiDTO?.map((reactionComent, key) => {
                                  if (
                                    reactionId === item?.projBoardTaskActiDTO?.projBoardTaskActiId &&
                                    openReactionComment
                                  ) {
                                    return (
                                      <div key={key} className="flex items-center">
                                        <button
                                          onClick={() =>
                                            onReactionComment(
                                              reactionComent?.reaction,
                                              item?.projBoardTaskActiDTO?.projBoardTaskActiId
                                            )
                                          }
                                        >
                                          <span className="flex items-center">
                                            {reactionComent?.reaction === 1 && (
                                              <Icon name="like_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 2 && (
                                              <Icon name="hearth_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 3 && (
                                              <Icon name="smile_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 4 && (
                                              <Icon name="sad_face_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 5 && (
                                              <Icon name="angry_reaction" className="hover:-translate-y-1" />
                                            )}
                                          </span>
                                        </button>
                                        <span className="pl-1 text-base">{reactionComent?.userId?.length}</span>
                                      </div>
                                    );
                                  }
                                  if (reactionComent?.userId?.length && !openReactionComment) {
                                    return (
                                      <div key={key} className="flex items-center">
                                        <button
                                          onClick={() =>
                                            onReactionComment(
                                              reactionComent?.reaction,
                                              item?.projBoardTaskActiDTO?.projBoardTaskActiId
                                            )
                                          }
                                        >
                                          <span className="flex items-center">
                                            {reactionComent?.reaction === 1 && (
                                              <Icon name="like_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 2 && (
                                              <Icon name="hearth_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 3 && (
                                              <Icon name="smile_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 4 && (
                                              <Icon name="sad_face_reaction" className="hover:-translate-y-1" />
                                            )}
                                            {reactionComent?.reaction === 5 && (
                                              <Icon name="angry_reaction" className="hover:-translate-y-1" />
                                            )}
                                          </span>
                                        </button>
                                        <span className="pl-1 text-base">{reactionComent?.userId?.length}</span>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Update - Delete */}
                        <div className="relative z-0 h-8 w-8 min-w-[32px]">
                          {!isUpdateComment && userInfo?.userID === item?.projBoardTaskActiDTO?.userID && (
                            <>
                              <div
                                onClick={(event) => {
                                  setIsComment(false);
                                  handleOperation(item?.projBoardTaskActiDTO?.projBoardTaskActiId);
                                  setOpenConfirmDeleteComment(false);
                                  event.stopPropagation();
                                }}
                                className="operation-button flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg bg-neu-500 p-4"
                              >
                                <Icon name="small_button" />
                              </div>
                              {isOperation && commentId === item?.projBoardTaskActiDTO?.projBoardTaskActiId && (
                                <ul className="operation-button absolute top-[110%] right-0 z-0 rounded-md bg-white text-center font-medium shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]">
                                  <button
                                    onClick={async (event) => {
                                      await setIsUpdateComment(true);
                                      await setIsOperation(false);
                                      setIsComment(false);
                                      onSetValueQuillUpdate(item);
                                      event.stopPropagation();
                                    }}
                                    className="flex w-full cursor-pointer gap-x-2 border-b p-2 hover:bg-primary-50"
                                  >
                                    <Icon name={'edit_2'} className="fill-neu-400 " />
                                    <p className="whitespace-nowrap">{t('project.edit-comments')}</p>
                                  </button>

                                  <button
                                    onClick={() => {
                                      setOpenConfirmDeleteComment(true);
                                      setOpenReactionComment(false);
                                      setIsOperation(false);
                                    }}
                                    className="flex cursor-pointer gap-x-2 p-2 text-neu-800 hover:bg-primary-50"
                                  >
                                    <Icon name={'trash_alt'} className="fill-red-500 " />
                                    <p className="whitespace-nowrap">{t('project.delete-comments')}</p>
                                  </button>
                                </ul>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {openConfirmDeleteComment && commentId === item?.projBoardTaskActiDTO?.projBoardTaskActiId && (
                        <div
                          className={`absolute ${
                            commentList?.indexOf(item) > commentList?.length - 2 ? 'bottom-0' : ''
                          } left-[20%] right-[20%] z-50 mt-3 w-[464px] rounded-lg bg-white pl-2 pr-2 pt-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]`}
                        >
                          <div className="flex flex-col items-center gap-y-5 p-4">
                            <Icon name="trash_can_1" />
                            <div className="text-center">
                              <p className="font-medium text-neu-400">{t('project.delete-comments')}</p>
                              <p className="mt-1 font-normal text-neu-200">
                                {t('project.comments-will-completely')} <br /> {t('project.this-action-undone')}
                              </p>
                            </div>

                            <div className="flex justify-center gap-x-10">
                              <Button
                                content={t('project.cancel')}
                                onClick={() => {
                                  setOpenConfirmDeleteComment(false);
                                }}
                                className="min-w-[82px] border border-neu-800 py-2 px-3 text-neu-800"
                              />

                              <Button
                                type="submit"
                                content={t('project.confirm')}
                                onClick={() => handleDelete(item?.projBoardTaskActiDTO?.projBoardTaskActiId)}
                                className={` min-w-[82px] cursor-pointer border  border-neu-800 bg-neu-800 py-2 px-3 text-neu-300`}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="pl-[52px]">
                        {isUpdateComment && commentId === item?.projBoardTaskActiDTO?.projBoardTaskActiId ? (
                          <div className="relative w-full max-w-[calc(100%-56px)]">
                            <CommentQuill
                              lengthValueComment={lengthValueComment}
                              setLengthValueComment={setLengthValueComment}
                              dataFilelink={dataFilelink}
                              setDetailTask={setDetailTask}
                              detailTask={projBoardTaskDetail}
                              ref={commentQuillUpdateRef}
                              setComment={setComment}
                              setIsComment={setIsUpdateComment}
                              comment={commentUpdate}
                              isEmpty={true}
                              handleChange={handleUpdateComment}
                              onSubmit={() => onSubmitUpdate(item?.projBoardTaskActiDTO?.projBoardTaskActiId)}
                              onKeyDownValue={handleKeyDown}
                            />
                            {showMembers && memberList?.length ? (
                              <div
                                ref={listRef}
                                className="absolute bottom-[calc(100%+16px)] h-48 w-full rounded-lg bg-neu-500 py-6 px-3"
                              >
                                <div className="font-semibold">{t('project.member')}</div>
                                <ul className="max-h-[calc(100%-20px)] overflow-y-auto">
                                  {memberList?.map((member, index) => {
                                    if (item?.userId !== userInfo?.userID) {
                                      return (
                                        <li
                                          key={index}
                                          onClick={() => handleTagName(member?.userId, member?.fullName)}
                                          className="flex cursor-pointer items-center justify-between gap-x-2 rounded-lg p-1.5 hover:bg-white"
                                        >
                                          <div className="flex items-center gap-x-2">
                                            <AttachFile
                                              attachType="UserAvatar"
                                              entity="users"
                                              seq={member?.userId}
                                              viewMode={true}
                                              mode={'comment' + index}
                                              className="h-8 w-8 rounded-full object-cover"
                                            />

                                            <div>{member?.fullName}</div>
                                          </div>
                                          <div>{member?.userName ? `@${member?.userName}` : ''}</div>
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <div className="p-4">
                            {item?.projBoardTaskActiDTO?.projBoardTaskActiId && (
                              <div>
                                {/* Parent comment */}

                                <div className="ml-auto flex w-[100%] items-center gap-2 break-words rounded-lg bg-neu-500 p-2">
                                  <span
                                    className="innerComment inline-block w-full whitespace-normal break-words leading-6"
                                    dangerouslySetInnerHTML={{
                                      __html: item?.projBoardTaskActiDTO.content
                                        .replaceAll('\\\\', '')
                                        .replaceAll('<p><br></p>', '')
                                        .replaceAll('<ul>', '<ul style="list-style: disc; padding-left:58px">')
                                    }}
                                  ></span>
                                </div>
                                <div>
                                  {/* Nút hiển thị số lượng phản hồi và đóng/mở danh sách phản hồi */}
                                  <div
                                    onClick={() => handleToggleReplies(item)}
                                    className="mt-3 cursor-pointer text-blue-500"
                                  >
                                    {item?.projBoardTaskActiDTO?.totalReplies === null ||
                                    item?.projBoardTaskActiDTO?.totalReplies === 0
                                      ? null // Không hiển thị gì nếu không có phản hồi
                                      : currentOpenParentId === item?.projBoardTaskActiDTO?.projBoardTaskActiId
                                      ? `${t('project.close_replies')}` // Nếu đang mở phản hồi
                                      : `${item?.projBoardTaskActiDTO?.totalReplies} ${t('project.replies')}`}
                                  </div>
                                  {/* Nút Trả lời để mở ô input */}
                                  <button onClick={() => handleReplyClick(item)} className="mt-2 text-blue-500">
                                    {t('project.reply')}
                                  </button>
                                </div>
                                {currentOpenParentId === item.projBoardTaskActiDTO.projBoardTaskActiId &&
                                  replies.length > 0 && (
                                    <div className="mt-4 pl-6">
                                      {replies.map(
                                        (reply, index) =>
                                          reply.projBoardTaskActiDTO.activityParentId ===
                                            item.projBoardTaskActiDTO.projBoardTaskActiId && (
                                            <div key={index} className="mt-6 flex items-start gap-4">
                                              {/* Avatar */}
                                              <div className="flex-shrink-0">
                                                <AttachFile
                                                  attachType="UserAvatar"
                                                  entity="users"
                                                  seq={reply.projBoardTaskActiDTO.userID}
                                                  viewMode={true}
                                                  mode={'relai' + index}
                                                  className="h-10 w-10 rounded-full object-cover"
                                                />
                                              </div>
                                              {/* Nội dung tin nhắn */}
                                              <div className="flex w-full flex-col gap-2">
                                                {/* Phần tên và thời gian */}
                                                <div className="flex items-center justify-start gap-4">
                                                  <div className="font-medium">
                                                    {reply.projBoardTaskActiDTO.fullName}
                                                  </div>
                                                  <div className="min-w-[94px] text-sm text-neu-200">
                                                    {reply.projBoardTaskActiDTO.createdAt && (
                                                      <ConvertTime
                                                        type="project"
                                                        timeInMileSec={
                                                          new Date().getTime() -
                                                          new Date(reply.projBoardTaskActiDTO.updatedAt).getTime()
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="relative flex items-center">
                                                    <button
                                                      className={`${
                                                        openReactionComment ? 'focus:ring-2 ' : ''
                                                      } focus:ring-gray-200`}
                                                      onClick={(event) => {
                                                        setReactionId(reply?.projBoardTaskActiDTO?.projBoardTaskActiId);
                                                        setOpenReactionComment(!openReactionComment);
                                                        event.stopPropagation();
                                                      }}
                                                    >
                                                      <svg
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 18 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5M6 11.25C6.25 11.75 7.2 12.75 9 12.75C10.8 12.75 11.75 11.75 12 11.25M6.23428 6.98435H6.51553M6.23428 7.2656H6.51553M11.4843 6.98435H11.7655M11.4843 7.2656H11.7655M6.75 7.125C6.75 7.33211 6.58211 7.5 6.375 7.5C6.16789 7.5 6 7.33211 6 7.125C6 6.91789 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.91789 6.75 7.125ZM12 7.125C12 7.33211 11.8321 7.5 11.625 7.5C11.4179 7.5 11.25 7.33211 11.25 7.125C11.25 6.91789 11.4179 6.75 11.625 6.75C11.8321 6.75 12 6.91789 12 7.125Z"
                                                          stroke="#9C9C9C"
                                                          strokeWidth="0.75"
                                                          strokeLinecap="round"
                                                        />
                                                        <g clipPath="url(#clip0_8907_16566)">
                                                          <path
                                                            d="M14.5003 12.168V16.8346M16.8337 14.5013L12.167 14.5013"
                                                            stroke="#9C9C9C"
                                                            strokeWidth="0.75"
                                                            strokeLinecap="round"
                                                          />
                                                        </g>
                                                        <defs>
                                                          <clipPath id="clip0_8907_16566">
                                                            <rect
                                                              width="7"
                                                              height="7"
                                                              fill="white"
                                                              transform="translate(11 11)"
                                                            />
                                                          </clipPath>
                                                        </defs>
                                                      </svg>
                                                    </button>
                                                    {reply?.reactionBoardTaskActiDTO
                                                      ?.map((item) => item.userId.length > 0)
                                                      .includes(true) ? (
                                                      <div className="absolute left-6 -top-2 z-20 flex h-[32px] w-auto scale-[90%] justify-between gap-2 rounded-[24px] border   bg-white px-4 shadow">
                                                        {reply?.reactionBoardTaskActiDTO?.map((reactionComent, key) => {
                                                          if (
                                                            reactionId ===
                                                              reply?.projBoardTaskActiDTO?.projBoardTaskActiId &&
                                                            openReactionComment
                                                          ) {
                                                            return (
                                                              <div key={key} className="flex items-center">
                                                                <button
                                                                  onClick={() =>
                                                                    onReactionComment(
                                                                      reactionComent?.reaction,
                                                                      reply?.projBoardTaskActiDTO?.projBoardTaskActiId
                                                                    )
                                                                  }
                                                                >
                                                                  <span className="flex items-center">
                                                                    {reactionComent?.reaction === 1 && (
                                                                      <Icon
                                                                        name="like_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 2 && (
                                                                      <Icon
                                                                        name="hearth_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 3 && (
                                                                      <Icon
                                                                        name="smile_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 4 && (
                                                                      <Icon
                                                                        name="sad_face_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 5 && (
                                                                      <Icon
                                                                        name="angry_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </button>
                                                                <span className="pl-1 text-base">
                                                                  {reactionComent?.userId?.length}
                                                                </span>
                                                              </div>
                                                            );
                                                          }
                                                          if (reactionComent?.userId?.length) {
                                                            return (
                                                              <div key={key} className="flex items-center">
                                                                <button
                                                                  onClick={() =>
                                                                    onReactionComment(
                                                                      reactionComent?.reaction,
                                                                      reply?.projBoardTaskActiDTO?.projBoardTaskActiId
                                                                    )
                                                                  }
                                                                >
                                                                  <span className="flex items-center">
                                                                    {reactionComent?.reaction === 1 && (
                                                                      <Icon
                                                                        name="like_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 2 && (
                                                                      <Icon
                                                                        name="hearth_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 3 && (
                                                                      <Icon
                                                                        name="smile_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 4 && (
                                                                      <Icon
                                                                        name="sad_face_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 5 && (
                                                                      <Icon
                                                                        name="angry_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </button>
                                                                <span className="pl-1 text-base">
                                                                  {reactionComent?.userId?.length}
                                                                </span>
                                                              </div>
                                                            );
                                                          }
                                                        })}
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className={`${
                                                          reactionId ===
                                                            reply?.projBoardTaskActiDTO?.projBoardTaskActiId &&
                                                          openReactionComment
                                                            ? 'absolute'
                                                            : 'hidden'
                                                        } left-6 -top-2 z-20 flex h-[32px] w-auto scale-[90%] justify-between gap-3 rounded-[24px] border bg-white px-2 shadow`}
                                                      >
                                                        {reply?.reactionBoardTaskActiDTO?.map((reactionComent, key) => {
                                                          if (
                                                            reactionId ===
                                                              reply?.projBoardTaskActiDTO?.projBoardTaskActiId &&
                                                            openReactionComment
                                                          ) {
                                                            return (
                                                              <div key={key} className="flex items-center">
                                                                <button
                                                                  onClick={() =>
                                                                    onReactionComment(
                                                                      reactionComent?.reaction,
                                                                      reply?.projBoardTaskActiDTO?.projBoardTaskActiId
                                                                    )
                                                                  }
                                                                >
                                                                  <span className="flex items-center">
                                                                    {reactionComent?.reaction === 1 && (
                                                                      <Icon
                                                                        name="like_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 2 && (
                                                                      <Icon
                                                                        name="hearth_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 3 && (
                                                                      <Icon
                                                                        name="smile_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 4 && (
                                                                      <Icon
                                                                        name="sad_face_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 5 && (
                                                                      <Icon
                                                                        name="angry_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </button>
                                                                <span className="pl-1 text-base">
                                                                  {reactionComent?.userId?.length}
                                                                </span>
                                                              </div>
                                                            );
                                                          }
                                                          if (reactionComent?.userId?.length && !openReactionComment) {
                                                            return (
                                                              <div key={key} className="flex items-center">
                                                                <button
                                                                  onClick={() =>
                                                                    onReactionComment(
                                                                      reactionComent?.reaction,
                                                                      reply?.projBoardTaskActiDTO?.projBoardTaskActiId
                                                                    )
                                                                  }
                                                                >
                                                                  <span className="flex items-center">
                                                                    {reactionComent?.reaction === 1 && (
                                                                      <Icon
                                                                        name="like_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 2 && (
                                                                      <Icon
                                                                        name="hearth_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 3 && (
                                                                      <Icon
                                                                        name="smile_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 4 && (
                                                                      <Icon
                                                                        name="sad_face_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                    {reactionComent?.reaction === 5 && (
                                                                      <Icon
                                                                        name="angry_reaction"
                                                                        className="hover:-translate-y-1"
                                                                      />
                                                                    )}
                                                                  </span>
                                                                </button>
                                                                <span className="pl-1 text-base">
                                                                  {reactionComent?.userId?.length}
                                                                </span>
                                                              </div>
                                                            );
                                                          }
                                                        })}
                                                      </div>
                                                    )}
                                                  </div>
                                                  {/* Update - Delete */}
                                                  <div className="relative  h-8 w-8 min-w-[32px] pl-36">
                                                    {!isUpdateRelies &&
                                                      userInfo?.userID === reply?.projBoardTaskActiDTO?.userID && (
                                                        <>
                                                          <div
                                                            onClick={(event) => {
                                                              setIsCommentRelies(false);
                                                              handleOperationRelies(
                                                                reply?.projBoardTaskActiDTO?.projBoardTaskActiId
                                                              );
                                                              setOpenConfirmDeleteRelies(false);
                                                              event.stopPropagation();
                                                            }}
                                                            className="operation-button flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg bg-neu-500 p-4"
                                                          >
                                                            <Icon name="small_button" />
                                                          </div>
                                                          {isOperationRelies &&
                                                            reliesId ===
                                                              reply?.projBoardTaskActiDTO?.projBoardTaskActiId && (
                                                              <ul className="operation-button absolute top-[110%] right-0 z-[99999]  rounded-md bg-white text-center font-medium shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]">
                                                                <button
                                                                  onClick={async (event) => {
                                                                    await setIsUpdateRelies(true);
                                                                    await setIsOperationRelies(false);
                                                                    setIsCommentRelies(false);
                                                                    onSetValueQuillUpdateRelies(reply);
                                                                    event.stopPropagation();
                                                                  }}
                                                                  className="flex w-full cursor-pointer gap-x-2 border-b p-2 hover:bg-primary-50"
                                                                >
                                                                  <Icon name="edit_2" className="fill-neu-400 " />
                                                                  <p className="whitespace-nowrap">
                                                                    {t('project.edit-comments')}
                                                                  </p>
                                                                </button>

                                                                <button
                                                                  onClick={(event) => {
                                                                    setOpenConfirmDeleteRelies(true);
                                                                    setOpenReactionComment(false);
                                                                    setIsOperationRelies(false);
                                                                    event.stopPropagation();
                                                                  }}
                                                                  className="flex cursor-pointer gap-x-2 p-2 text-neu-800 hover:bg-primary-50"
                                                                >
                                                                  <Icon name="trash_alt" className="fill-red-500 " />
                                                                  <p className="whitespace-nowrap">
                                                                    {t('project.delete-comments')}
                                                                  </p>
                                                                </button>
                                                              </ul>
                                                            )}
                                                        </>
                                                      )}
                                                    {openConfirmDeleteRelies &&
                                                      reliesId === reply?.projBoardTaskActiDTO?.projBoardTaskActiId && (
                                                        <div
                                                          className={`absolute ${
                                                            commentList?.indexOf(item) > commentList?.length - 2
                                                              ? 'bottom-0'
                                                              : ''
                                                          } right-[20%] z-[9999999] mt-3 w-[464px] rounded-lg bg-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]`}
                                                        >
                                                          <div className="flex flex-col items-center gap-y-5 p-4">
                                                            <Icon name="trash_can_1" />
                                                            <div className="text-center">
                                                              <p className="font-medium text-neu-400">
                                                                {t('project.delete-comments')}
                                                              </p>
                                                              <p className="mt-1 font-normal text-neu-200">
                                                                {t('project.comments-will-completely')} <br />
                                                                {t('project.this-action-undone')}
                                                              </p>
                                                            </div>
                                                            <div className="flex justify-center gap-x-10">
                                                              <Button
                                                                content={t('project.cancel')}
                                                                onClick={() => {
                                                                  setOpenConfirmDeleteRelies(false);
                                                                }}
                                                                className="min-w-[82px] border border-neu-800 py-2 px-3 text-neu-800"
                                                              />
                                                              <Button
                                                                type="submit"
                                                                content={t('project.confirm')}
                                                                onClick={() =>
                                                                  handleDeleteRelies(
                                                                    reply?.projBoardTaskActiDTO?.projBoardTaskActiId
                                                                  )
                                                                }
                                                                className="min-w-[82px] cursor-pointer border border-neu-800 bg-neu-800 py-2 px-3 text-neu-300"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>

                                                {isUpdateRelies &&
                                                  reliesId === reply?.projBoardTaskActiDTO?.projBoardTaskActiId && (
                                                    <div className="relative w-full max-w-[calc(100%-56px)]">
                                                      <CommentQuill
                                                        lengthValueComment={lengthValueCommentReplies}
                                                        setLengthValueComment={setLengthValueCommentReplies}
                                                        dataFilelink={dataFilelink}
                                                        setDetailTask={setDetailTask}
                                                        detailTask={projBoardTaskDetail}
                                                        ref={reliesQuillUpdateRef}
                                                        setComment={setReplyText}
                                                        setIsComment={setIsUpdateRelies}
                                                        comment={reliesUpdate}
                                                        isEmpty={true}
                                                        handleChange={handleUpdateRelies}
                                                        onSubmit={() =>
                                                          onSubmitUpdateRelies(
                                                            reply?.projBoardTaskActiDTO?.projBoardTaskActiId
                                                          )
                                                        }
                                                        onKeyDownValue={handleKeyDown}
                                                      />

                                                      {showMembers && memberList?.length ? (
                                                        <div
                                                          ref={listRefReplies}
                                                          className="absolute bottom-[calc(100%+16px)] h-48 w-full rounded-lg bg-neu-500 py-6 px-3"
                                                        >
                                                          <div className="font-semibold">{t('project.member')}</div>
                                                          <ul className="max-h-[calc(100%-20px)] overflow-y-auto">
                                                            {memberList?.map((member, index) => {
                                                              if (item?.userId !== userInfo?.userID) {
                                                                return (
                                                                  <li
                                                                    key={index}
                                                                    onClick={() =>
                                                                      handleTagName(member?.userId, member?.fullName)
                                                                    }
                                                                    className="flex cursor-pointer items-center justify-between gap-x-2 rounded-lg p-1.5 hover:bg-white"
                                                                  >
                                                                    <div className="flex items-center gap-x-2">
                                                                      <AttachFile
                                                                        attachType="UserAvatar"
                                                                        entity="users"
                                                                        seq={member?.userId}
                                                                        viewMode={true}
                                                                        mode={'reply' + index}
                                                                        className="h-8 w-8 rounded-full object-cover"
                                                                      />

                                                                      <div>{member?.fullName}</div>
                                                                    </div>
                                                                    <div>
                                                                      {member?.userName ? `@${member?.userName}` : ''}
                                                                    </div>
                                                                  </li>
                                                                );
                                                              }
                                                            })}
                                                          </ul>
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  )}

                                                {/* Phần nội dung */}
                                                <div className="ml-auto flex w-[100%] items-center gap-2 break-all rounded-lg bg-neu-500 p-2">
                                                  <span
                                                    className="innerComment inline-block w-full overflow-hidden whitespace-normal leading-6"
                                                    dangerouslySetInnerHTML={{
                                                      __html: reply.projBoardTaskActiDTO.content
                                                        .replaceAll('\\\\', '')
                                                        .replaceAll('<p><br></p>', '')
                                                        .replaceAll(
                                                          '<ul>',
                                                          '<ul style="list-style: disc; padding-left:58px">'
                                                        )
                                                    }}
                                                  ></span>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                      )}
                                    </div>
                                  )}

                                {/* Reply input */}
                                {currentOpenParentId === item.projBoardTaskActiDTO.projBoardTaskActiId &&
                                  showReplyInput && (
                                    <div className="mt-8 flex gap-3 pl-6">
                                      <AttachFile
                                        attachType="UserAvatar"
                                        entity="users"
                                        seq={userInfo?.userID}
                                        viewMode={true}
                                        mode={'relai'}
                                        className="h-10 w-10 rounded-full object-cover"
                                      />
                                      {isCommentRelies ? (
                                        <div className="relative w-full ">
                                          {showMembers && memberList?.length ? (
                                            <div
                                              ref={listRefReplies}
                                              className="absolute bottom-[calc(100%+16px)] h-48 w-full rounded-lg bg-neu-500 py-6 px-3"
                                            >
                                              <div className="font-semibold">{t('department.member')}</div>
                                              <ul className="max-h-[calc(100%-20px)] overflow-y-auto">
                                                {memberList?.map((item, index) => {
                                                  if (item?.userId !== userInfo?.userID) {
                                                    return (
                                                      <li
                                                        key={index}
                                                        onClick={() => handleTagName(item?.userId, item?.fullName)}
                                                        className="flex cursor-pointer items-center justify-between gap-x-2 rounded-lg p-1.5 hover:bg-white"
                                                      >
                                                        <div className="flex items-center gap-x-2">
                                                          <AttachFile
                                                            attachType="UserAvatar"
                                                            entity="users"
                                                            seq={item?.userId}
                                                            viewMode={true}
                                                            mode={'reply' + index}
                                                            className="h-10 w-10 rounded-full object-cover"
                                                          />
                                                          <div>{item?.fullName}</div>
                                                        </div>
                                                        <div>{item?.userName ? `@${item?.userName}` : ''}</div>
                                                      </li>
                                                    );
                                                  }
                                                })}
                                              </ul>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="mt-2 flex flex-col gap-2 ">
                                            <CommentQuill
                                              lengthValueComment={lengthValueCommentReplies}
                                              setLengthValueComment={setLengthValueCommentReplies}
                                              dataFilelink={dataFilelink}
                                              setDetailTask={setDetailTask}
                                              detailTask={projBoardTaskDetail}
                                              ref={RepiesQuillRef}
                                              setIsComment={setIsCommentRelies}
                                              setComment={setReplyText}
                                              comment={replyText}
                                              isEmpty={true}
                                              handleChange={handleChangeReplies}
                                              onSubmit={() =>
                                                handleReplySubmit(item.projBoardTaskActiDTO.projBoardTaskActiId)
                                              }
                                              onKeyDownValue={handleKeyDown}
                                              theme="snow"
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          onClick={async (event) => {
                                            if (projectInfo?.roleProject === 3) {
                                              return;
                                            }
                                            setComment('');
                                            await setIsCommentRelies(true);
                                            setIsUpdateComment(false);
                                            setIsOperation(false);
                                            if (commentQuillRef.current) {
                                              commentQuillRef.current.focus();
                                            }
                                            event.stopPropagation();
                                          }}
                                          className="w-full cursor-text rounded-lg bg-neu-500 p-3 text-sm text-neu-200"
                                        >
                                          {t('project.enter-comment')}
                                        </div>
                                      )}
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="flex items-center gap-x-4 pl-[40px]">
                        <li className=" min-w-[94px] text-neu-200">
                          {item?.projBoardTaskActiDTO?.createdAt !== item?.projBoardTaskActiDTO?.updatedAt &&
                            `(${t('project.edited')})`}
                        </li>
                      </div>
                    </div>
                  </div>
                );
              })}

            {showActivity &&
              activityList?.map((item, index) => {
                if (item?.content !== null) {
                  return (
                    <div
                      key={index}
                      className="flex w-full items-center justify-between gap-x-4 border-b py-3 last:border-none"
                    >
                      {/* Avatar - content */}
                      <div className="flex w-full flex-col gap-x-4">
                        <div className="flex items-center justify-between gap-4">
                          <div className="flex items-center gap-2 ">
                            <div onClick={() => changeIdUserInfomation(item?.createBy)} className="cursor-pointer">
                              <AttachFile
                                attachType="UserAvatar"
                                entity="users"
                                seq={item?.createBy}
                                viewMode={true}
                                mode={'comment' + index}
                                className="h-10 min-h-[40px] w-10 min-w-[40px] rounded-full object-cover"
                              />
                            </div>
                            <span className="pl-2">{item?.fullName}</span>
                            {/* Time */}
                            <div className="flex items-center gap-x-4">
                              <li className="ml-8 min-w-[94px] list-disc text-neu-200">
                                {item?.createdAt && (
                                  <ConvertTime
                                    type="project"
                                    timeInMileSec={new Date().getTime() - new Date(item?.createdAt).getTime()}
                                  />
                                )}
                              </li>
                            </div>
                            <div className="relative flex items-center">
                              <button
                                className={`${openReactionComment ? 'focus:ring-2 ' : ''} focus:ring-gray-200`}
                                onClick={(event) => {
                                  setReactionId(item?.projBoardTaskActiId);
                                  setOpenReactionComment(!openReactionComment);
                                  event.stopPropagation();
                                }}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5M6 11.25C6.25 11.75 7.2 12.75 9 12.75C10.8 12.75 11.75 11.75 12 11.25M6.23428 6.98435H6.51553M6.23428 7.2656H6.51553M11.4843 6.98435H11.7655M11.4843 7.2656H11.7655M6.75 7.125C6.75 7.33211 6.58211 7.5 6.375 7.5C6.16789 7.5 6 7.33211 6 7.125C6 6.91789 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.91789 6.75 7.125ZM12 7.125C12 7.33211 11.8321 7.5 11.625 7.5C11.4179 7.5 11.25 7.33211 11.25 7.125C11.25 6.91789 11.4179 6.75 11.625 6.75C11.8321 6.75 12 6.91789 12 7.125Z"
                                    stroke="#9C9C9C"
                                    strokeWidth="0.75"
                                    strokeLinecap="round"
                                  />
                                  <g clipPath="url(#clip0_8907_16566)">
                                    <path
                                      d="M14.5003 12.168V16.8346M16.8337 14.5013L12.167 14.5013"
                                      stroke="#9C9C9C"
                                      strokeWidth="0.75"
                                      strokeLinecap="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_8907_16566">
                                      <rect width="7" height="7" fill="white" transform="translate(11 11)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                              {item?.reactionBoardTaskActiDTO?.map((item) => item.userId.length > 0).includes(true) ? (
                                <div className="absolute left-6 -top-2 z-20 flex h-[32px] w-auto scale-[90%] justify-between gap-2 rounded-[24px] border   bg-white px-4 shadow">
                                  {item?.reactionBoardTaskActiDTO?.map((reactionComent, key) => {
                                    if (reactionId === item?.projBoardTaskActiId && openReactionComment) {
                                      return (
                                        <div key={key} className="flex items-center">
                                          <button
                                            onClick={() =>
                                              onReactionComment(reactionComent?.reaction, item?.projBoardTaskActiId)
                                            }
                                          >
                                            <span className="">
                                              {reactionComent?.reaction === 1 && (
                                                <Icon name="like_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 2 && (
                                                <Icon name="hearth_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 3 && (
                                                <Icon name="smile_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 4 && (
                                                <Icon name="sad_face_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 5 && (
                                                <Icon name="angry_reaction" className="hover:-translate-y-1" />
                                              )}
                                            </span>
                                          </button>
                                          <span className="pl-1 text-base">{reactionComent?.userId?.length}</span>
                                        </div>
                                      );
                                    }
                                    if (reactionComent?.userId?.length) {
                                      return (
                                        <div key={key} className="flex items-center">
                                          <button
                                            onClick={() =>
                                              onReactionComment(
                                                reactionComent?.reaction,
                                                item?.projBoardTaskActiDTO?.projBoardTaskActiId
                                              )
                                            }
                                          >
                                            <span className="flex items-center">
                                              {reactionComent?.reaction === 1 && (
                                                <Icon name="like_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 2 && (
                                                <Icon name="hearth_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 3 && (
                                                <Icon name="smile_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 4 && (
                                                <Icon name="sad_face_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 5 && (
                                                <Icon name="angry_reaction" className="hover:-translate-y-1" />
                                              )}
                                            </span>
                                          </button>
                                          <span className="pl-1 text-base">{reactionComent?.userId?.length}</span>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              ) : (
                                <div
                                  className={`${
                                    reactionId === item?.projBoardTaskActiId && openReactionComment
                                      ? 'absolute'
                                      : 'hidden'
                                  } left-6 -top-2 z-20 flex h-[32px] w-auto scale-[90%] justify-between gap-3 rounded-[24px] border bg-white px-2 shadow`}
                                >
                                  {item?.reactionBoardTaskActiDTO?.map((reactionComent, key) => {
                                    if (reactionId === item?.projBoardTaskActiId && openReactionComment) {
                                      return (
                                        <div key={key} className="flex items-center">
                                          <button
                                            onClick={() =>
                                              onReactionComment(reactionComent?.reaction, item?.projBoardTaskActiId)
                                            }
                                          >
                                            <span className="flex items-center">
                                              {reactionComent?.reaction === 1 && (
                                                <Icon name="like_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 2 && (
                                                <Icon name="hearth_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 3 && (
                                                <Icon name="smile_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 4 && (
                                                <Icon name="sad_face_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 5 && (
                                                <Icon name="angry_reaction" className="hover:-translate-y-1" />
                                              )}
                                            </span>
                                          </button>
                                          <span className="pl-1 text-base">{reactionComent?.userId?.length}</span>
                                        </div>
                                      );
                                    }
                                    if (reactionComent?.userId?.length && !openReactionComment) {
                                      return (
                                        <div key={key} className="flex items-center">
                                          <button
                                            onClick={() =>
                                              onReactionComment(reactionComent?.reaction, item?.projBoardTaskActiId)
                                            }
                                          >
                                            <span className="flex items-center">
                                              {reactionComent?.reaction === 1 && (
                                                <Icon name="like_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 2 && (
                                                <Icon name="hearth_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 3 && (
                                                <Icon name="smile_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 4 && (
                                                <Icon name="sad_face_reaction" className="hover:-translate-y-1" />
                                              )}
                                              {reactionComent?.reaction === 5 && (
                                                <Icon name="angry_reaction" className="hover:-translate-y-1" />
                                              )}
                                            </span>
                                          </button>
                                          <span className="pl-1 text-base">{reactionComent?.userId?.length}</span>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              )}
                            </div>
                          </div>

                          {/* Update - Delete */}
                          <div className="relative z-0 h-8 w-8 min-w-[32px]">
                            {!isUpdateComment && userInfo?.userID === item?.createBy && (
                              <>
                                <div
                                  onClick={(event) => {
                                    setIsComment(false);
                                    handleOperation(item?.projBoardTaskActiId);
                                    setOpenConfirmDeleteComment(false);
                                    event.stopPropagation();
                                  }}
                                  className="operation-button flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg bg-neu-500 p-4"
                                >
                                  <Icon name="small_button" />
                                </div>
                                {isOperation && commentId === item?.projBoardTaskActiId && (
                                  <ul className="operation-button absolute right-0 top-[110%] z-0 rounded-md bg-white text-center font-medium shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]">
                                    <button
                                      onClick={async (event) => {
                                        await setIsUpdateComment(true);
                                        await setIsOperation(false);
                                        setIsComment(false);
                                        onSetValueQuillUpdate(item);
                                        event.stopPropagation();
                                      }}
                                      className="flex w-full cursor-pointer gap-x-2 border-b p-2 hover:bg-primary-50"
                                    >
                                      <Icon name={'edit_2'} className="fill-neu-400 " />
                                      <p className="whitespace-nowrap">Sửa bình luận</p>
                                    </button>

                                    <button
                                      onClick={() => {
                                        setOpenConfirmDeleteComment(true);
                                        setOpenReactionComment(false);
                                      }}
                                      className="flex cursor-pointer gap-x-2 p-2 text-neu-800 hover:bg-primary-50"
                                    >
                                      <Icon name={'trash_alt'} className="fill-red-500 " />
                                      <p className="whitespace-nowrap">Xóa bình luận</p>
                                    </button>
                                  </ul>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {openConfirmDeleteComment && commentId === item?.projBoardTaskActiId && (
                          <div className="absolute left-[20%] right-[20%] z-50 mt-3 w-[464px] rounded-lg bg-white pl-2 pr-2 pt-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.20)]">
                            <div className="flex flex-col items-center gap-y-5 p-4">
                              <Icon name="trash_can_1" />

                              <div className="text-center">
                                <p className="font-medium text-neu-400">
                                  {`Xóa công việc `}
                                  <span className="font-bold">{item?.childTaskName}</span>
                                </p>
                                <p className="mt-1 font-normal text-neu-200">
                                  Các thông tin trong thẻ sẽ được xóa hoàn toàn. <br /> Hành động này sẽ không thể khôi
                                  phục
                                </p>
                              </div>

                              <div className="flex justify-center gap-x-10">
                                <Button
                                  content={'Hủy'}
                                  onClick={() => {
                                    setOpenConfirmDeleteComment(false);
                                  }}
                                  className="min-w-[82px] border border-neu-800 py-2 px-3 text-neu-800"
                                />

                                <Button
                                  type="submit"
                                  content={'Xác nhận'}
                                  onClick={() => handleDelete(item?.projBoardTaskActiId)}
                                  className={` min-w-[82px] cursor-pointer border  border-neu-800 bg-neu-800 py-2 px-3 text-neu-300`}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="pl-[40px]">
                          {isUpdateComment && commentId === item?.projBoardTaskActiId ? (
                            <div className="relative w-full max-w-[calc(100%-56px)]">
                              <CommentQuill
                                lengthValueComment={lengthValueComment}
                                setLengthValueComment={setLengthValueComment}
                                setDetailTask={setDetailTask}
                                detailTask={projBoardTaskDetail}
                                ref={commentQuillUpdateRef}
                                setComment={setComment}
                                setIsComment={setIsUpdateComment}
                                comment={commentUpdate}
                                isEmpty={true}
                                handleChange={handleUpdateComment}
                                onSubmit={() => onSubmitUpdate(item?.projBoardTaskActiId)}
                                onKeyDownValue={handleKeyDown}
                              />
                              {showMembers && memberList?.length ? (
                                <div
                                  ref={listRef}
                                  className="absolute bottom-[calc(100%+16px)] h-48 w-full rounded-lg bg-neu-500 py-6 px-3"
                                >
                                  <div className="font-semibold">{t('project.member')}</div>
                                  <ul className="max-h-[calc(100%-20px)] overflow-y-auto">
                                    {memberList?.map((member, index) => {
                                      if (item?.userId !== userInfo?.userID) {
                                        return (
                                          <li
                                            key={index}
                                            onClick={() => handleTagName(member?.userId, member?.fullName)}
                                            className="flex cursor-pointer items-center justify-between gap-x-2 rounded-lg p-1.5 hover:bg-white"
                                          >
                                            <div className="flex items-center gap-x-2">
                                              <AttachFile
                                                attachType="UserAvatar"
                                                entity="users"
                                                seq={member?.userId}
                                                viewMode={true}
                                                mode={'comment' + index}
                                                className="h-8 w-8 rounded-full object-cover"
                                              />

                                              <div>{member?.fullName}</div>
                                            </div>
                                            <div>{member?.userName ? `@${member?.userName}` : ''}</div>
                                          </li>
                                        );
                                      }
                                    })}
                                  </ul>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <p className="innerComment rounded-[8px] bg-neu-500 p-2">
                              <span className=" leading-6" dangerouslySetInnerHTML={{ __html: item?.content }}></span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  let newHtml = userInfo?.language === 'en' ? item?.contentEN : item?.contentVI;

                  // Trường hợp tạo mới nhãn
                  const matchCreat = item.contentVI.match(
                    /<span>([^<]+)<\/span> đã tạo mới nhãn <span>([^<]+) - (#\w{6})<\/span> trong bảng này\./
                  );
                  if (matchCreat) {
                    const name = matchCreat[1].trim();
                    const label = matchCreat[2].trim();
                    const color = matchCreat[3];
                    const modifiedContentVI = `<span style=""><span>${name}</span> đã tạo mới nhãn <span class="flex items-center">${label} - <div style="background-color:${color}" class="bg-[${color}] w-6 h-4 ml-2 rounded"> </div></span> trong bảng này.</span>`;
                    newHtml = modifiedContentVI;
                  }

                  // Trường hợp gán nhãn
                  const matchAssign = item.contentVI.match(
                    /<span>([^<]+)<\/span> đã gán <span>([^<]+) - (#\w{6})<\/span> vào trong thẻ công việc <span>([^<]+)<\/span>/
                  );
                  if (matchAssign) {
                    const name = matchAssign[1].trim();
                    const label = matchAssign[2].trim();
                    const color = matchAssign[3];
                    const taskName = matchAssign[4];

                    if (label === 'null') {
                      const modifiedContentVI = `<span style=""><span>${name}</span> đã gán <span class="flex items-center">Label - <div style="background-color:${color}" class="bg-[${color}] w-6 h-4 ml-2 rounded"> </div></span> vào trong thẻ công việc <span>${taskName}</span>.</span>`;
                      newHtml = modifiedContentVI;
                    } else {
                      const modifiedContentVI = `<span style=""><span>${name}</span> đã gán <span class="flex items-center">${label} - <div style="background-color:${color}" class="bg-[${color}] w-6 h-4 ml-2 rounded"> </div></span> vào trong thẻ công việc <span>${taskName}</span>.</span>`;
                      newHtml = modifiedContentVI;
                    }
                  }

                  // Trường hợp gỡ nhãn
                  const matchRemoveLabel = item.contentVI.match(
                    /<span>([^<]+)<\/span> đã gỡ nhãn <span>([^<]+) - (#\w{6})<\/span> khỏi thẻ công việc <span>([^<]+)<\/span>/
                  );
                  if (matchRemoveLabel) {
                    const name = matchRemoveLabel[1].trim();
                    const label = matchRemoveLabel[2].trim(); // label có thể là 'null'
                    const color = matchRemoveLabel[3];
                    const taskName = matchRemoveLabel[4];

                    let modifiedContentVI;
                    if (label === 'null') {
                      // Nếu label là 'null', hiển thị mã màu với màu thực tế
                      modifiedContentVI = `<span style=""><span>${name}</span> đã gỡ nhãn <span class="flex items-center">Label - <div style="background-color:${color};" class="w-6 h-4 ml-2 rounded"></div></span> khỏi thẻ công việc <span>${taskName}</span>.</span>`;
                    } else {
                      // Nếu có label
                      modifiedContentVI = `<span style=""><span>${name}</span> đã gỡ nhãn <span class="flex items-center">${label} - <div style="background-color:${color};" class="w-6 h-4 ml-2 rounded"></div></span> khỏi thẻ công việc <span>${taskName}</span>.</span>`;
                    }
                    newHtml = modifiedContentVI;
                  }

                  return (
                    <div
                      className="flex gap-4 border-b py-4"
                      key={index}
                      onClick={() => (item?.fileLink ? getDataLink(item?.fileLink) : null)}
                    >
                      <div onClick={() => changeIdUserInfomation(item?.createBy)} className="cursor-pointer">
                        <AttachFile
                          attachType="UserAvatar"
                          entity="users"
                          seq={item?.createBy}
                          viewMode={true}
                          mode={'comment' + index}
                          className="h-10 min-h-[40px] w-10 min-w-[40px] rounded-full object-cover"
                        />
                      </div>

                      <div className="notifications-list mr-2 w-full">
                        <p
                          className="notify-title overflow-wrap break-words text-sm text-[#172b4d]"
                          dangerouslySetInnerHTML={{ __html: newHtml }}
                          style={{
                            wordBreak: 'break-word', // Đảm bảo các từ dài xuống dòng
                            overflowWrap: 'break-word' // Xuống dòng nếu cần
                          }}
                        ></p>
                        {item?.fileLink !== null && item?.fileLink === dataLink?.projBoardTaskFileLinkId && (
                          <div>
                            <img
                              src={dataLink?.fileLink}
                              alt="avatar"
                              className="relative h-[86px] w-[120px]  rounded-xl object-cover"
                            />
                          </div>
                        )}
                        {item?.fileSeq && (
                          <div className="my-2">
                            <img
                              src={backendURL + '/file/' + 'displayImg/' + parseInt(item?.fileSeq)}
                              alt="avatar"
                              className="relative h-[86px] w-[120px]  rounded-xl object-cover"
                            />
                          </div>
                        )}

                        <p className="pt-1 text-xs font-medium text-neu-200">
                          <ConvertTime timeInMileSec={new Date().getTime() - new Date(item?.createdAt).getTime()} />
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
          </ul>

          {paging < totalPages - 1 && (
            <button
              onClick={handleShowMore}
              className="flex w-full items-center justify-center gap-x-2 rounded-lg bg-primary-300 p-2 text-primary-100 transition-all duration-100"
            >
              <span>{t('project.show-more')}</span>
              <Icon
                name={`${isLoading ? 'loading' : 'chevron_down'}`}
                className={`h-6 w-6 stroke-primary-100 ${isLoading ? 'animate-spin' : ''}`}
              />
            </button>
          )}

          {isLoading && (
            <Icon name={'loading'} className={`h-6 w-6 stroke-primary-100 ${isLoading ? 'animate-spin' : ''}`} />
          )}
          {paging === totalPages - 1 && totalPages > 1 && (
            <button
              onClick={() => {
                setPaging(0);
              }}
              className="flex w-full items-center justify-center gap-x-2 rounded-lg bg-primary-300 p-2 text-primary-100 transition-all duration-100"
            >
              <span>{t('project.collapse')}</span>
              <Icon
                name={`${isLoading ? 'loading' : 'chevron_down'}`}
                className={`h-6 w-6 rotate-180 stroke-primary-100 ${isLoading ? 'animate-spin' : ''}`}
              />
            </button>
          )}
        </>
      ) : (
        // There are no comments yet
        <div className="mt-3 flex flex-col items-center gap-y-3">
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M35.5537 7.19449C31.8278 4.89645 27.4125 3.63281 22.8516 3.63281C10.436 3.63281 0 12.8828 0 24.7266C0 28.8791 1.29 32.8598 3.74039 36.2978L0.317344 47.0446C-0.0442969 48.1798 0.806016 49.3359 1.99254 49.3359C2.26383 49.3359 2.53688 49.2732 2.78883 49.145L13.1988 43.852C13.6204 44.0336 14.0471 44.2024 14.4783 44.3592C12.0681 40.5973 10.7812 36.2789 10.7812 31.7578C10.7812 18.2933 22.1107 7.94203 35.5537 7.19449Z"
              fill="#7895FF"
              fillOpacity="0.4"
            />
            <path
              d="M56.2596 43.329C58.71 39.8911 60 35.9104 60 31.7578C60 19.9097 49.5595 10.6641 37.1484 10.6641C24.7329 10.6641 14.2969 19.914 14.2969 31.7578C14.2969 43.6059 24.7373 52.8516 37.1484 52.8516C40.4786 52.8516 43.7967 52.1729 46.8006 50.883L57.2112 56.1763C57.8429 56.4975 58.6061 56.4079 59.1462 55.9488C59.6863 55.4898 59.8979 54.7513 59.6828 54.0759L56.2596 43.329ZM30 33.5156C29.0292 33.5156 28.2422 32.7286 28.2422 31.7578C28.2422 30.787 29.0292 30 30 30C30.9708 30 31.7578 30.787 31.7578 31.7578C31.7578 32.7286 30.9708 33.5156 30 33.5156ZM37.0312 33.5156C36.0605 33.5156 35.2734 32.7286 35.2734 31.7578C35.2734 30.787 36.0605 30 37.0312 30C38.002 30 38.7891 30.787 38.7891 31.7578C38.7891 32.7286 38.002 33.5156 37.0312 33.5156ZM44.0625 33.5156C43.0917 33.5156 42.3047 32.7286 42.3047 31.7578C42.3047 30.787 43.0917 30 44.0625 30C45.0333 30 45.8203 30.787 45.8203 31.7578C45.8203 32.7286 45.0333 33.5156 44.0625 33.5156Z"
              fill="#7895FF"
              fillOpacity="0.4"
            />
          </svg>

          <p className="max-w-md text-center text-sm text-neu-200">{t('project.comment-title-empty')}</p>
        </div>
      )}
    </div>
  );
};

export default Comment;
