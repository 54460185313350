import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import projectBoard from '../../../../../Apis/ProjectManagement/TaskProgress/board';
import AttachFile from '../../../../AttachFile/AttachFile';
import Icon from '../../../../Common/Icon';

const PopupMember = ({ detailTask, setDetailTask, setSearchMember, setIsCallBack, isCallBack, projectInfo }) => {
  const { t } = useTranslation();
  const { boardId } = useParams();
  const [membeSearched, setMembeSearched] = useState(null);

  const memberListRef = useRef();
  const handleSearchMember = async (e) => {
    const { data } = await projectBoard.searchMemberTask(
      detailTask?.projBoardTaskId,
      boardId,
      e?.target?.value ? e?.target?.value : ''
    );
    setMembeSearched(data?.dataList);
  };
  useEffect(() => {
    if (detailTask?.projBoardTaskId) {
      handleSearchMember();
    }
  }, [detailTask?.projBoardTaskId]);
  // Thêm thành viên vào task
  const [waitAddproject, setWaitAddproject] = useState(true);

  const handleAddMember = async (data) => {
    setWaitAddproject(false);
    try {
      const newData = {
        actionFlag: 'addMemTask',
        arrMemberId: [data.memberId],
        projBoardTaskId: parseInt(detailTask?.projBoardTaskId)
      };

      await projectBoard.addMemberChildTask(newData);
      setDetailTask((prevTask) => ({
        ...prevTask,
        projBoardTaskMemDTOs: [...prevTask.projBoardTaskMemDTOs, data]
      }));
      setIsCallBack(!isCallBack);
      setWaitAddproject(true);
    } catch (error) {
      setWaitAddproject(true);
      console.log(error);
    }
  };
  // Xóa thành viên
  const handleDeleteMember = async (data) => {
    setWaitAddproject(false);
    try {
      await projectBoard.deleteMemberTask(data.memberId, detailTask?.projBoardTaskId, boardId);
      let updatedMemberTask = [...detailTask.projBoardTaskMemDTOs];
      const index = detailTask.projBoardTaskMemDTOs.findIndex((item) => item.memberId === data.memberId);
      updatedMemberTask.splice(index, 1);
      setDetailTask((prevTask) => ({
        ...prevTask,
        projBoardTaskMemDTOs: updatedMemberTask
      }));
      setIsCallBack(!isCallBack);
      setWaitAddproject(true);
    } catch (error) {
      setWaitAddproject(true);
      console.log(error);
    }
  };
  const markedArray1 = membeSearched?.map((item1) => ({
    ...item1,
    isChecked: detailTask?.projBoardTaskMemDTOs?.some((item2) => item2.userId === item1.userId)
  }));

  useEffect(() => {
    if (projectInfo?.roleProject === 3 && memberListRef?.current) {
      const checkboxes = memberListRef?.current?.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.disabled = true;
      });
    }
  }, [projectInfo, markedArray1]);

  return (
    <div>
      <div className="w-[350px] rounded-md border p-3 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.12)]">
        <div className="mb-3">
          <div className="mb-2 flex justify-between">
            <p></p>
            <p className="text-center font-semibold">{t('project.member')}</p>
            <p onClick={() => setSearchMember(false)} className="cursor-pointer">
              <Icon name="close_cross" className="stroke-black" />
            </p>
          </div>
          <div className="relative">
            <Icon name="zoom_search" className="absolute top-2 left-2 stroke-[#9C9C9C]" />
            <input
              className="  w-full rounded-lg border border-[#F1F3FD] bg-white py-1.5  pl-8 pr-2 text-sm font-medium  placeholder:text-xs placeholder:font-normal focus:border-[#234EEC]  focus:outline-none"
              placeholder={t('project.search-for-members')}
              onChange={handleSearchMember}
            />
          </div>
        </div>
        {waitAddproject === false && (
          <div className="flex h-[30vh] flex-col items-center justify-center gap-3">
            <div>
              <span className="loaders"></span>
            </div>
            <div>
              <p className="text-sm">{t('project.adding-members')} ...</p>
            </div>
          </div>
        )}
        <div className={`${waitAddproject ? '' : 'hidden'}`}>
          <div ref={memberListRef} className="max-h-[300px] min-h-[40px] overflow-y-auto">
            {markedArray1?.map((item, index) => {
              return (
                <div
                  className="flex cursor-pointer gap-4 rounded-lg p-2"
                  key={index}
                  onClick={() => {
                    if (projectInfo?.roleProject !== 3) {
                      item?.isChecked == false ? handleAddMember(item) : handleDeleteMember(item);
                    }
                  }}
                >
                  <input checked={item?.isChecked == true} type="checkbox" />
                  <AttachFile
                    attachType="UserAvatar"
                    entity="users"
                    seq={item?.userId}
                    viewMode={true}
                    mode={'user-projects' + index}
                    className="h-[40px] w-[40px] rounded-full border object-cover"
                  />
                  <div className="">
                    <p className="font-normal text-black">{item.fullName}</p>
                    <p className="text-[#9C9C9C]">@{item.userName}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupMember;
